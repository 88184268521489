import React, { Component } from 'react';
import Tabulator from "tabulator-tables"; //import Tabulator library

import { getEmbeddedChild } from '../../class/array';
import { cleanUpTabulatorColumns, setLocalStorageValueByParameter, toggleLoader } from '../../class/common.js';
import {
  ACCOUNT_AMOUNT_FIELDS, ACCOUNT_AMOUNT_TITLES,
  ALL_WIDGETS,
  API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CALCULATED_COLUMNS as CALC_COLS,
  CC_LABELS,
  DEFINE_FIELDS,
  DIALOG_SIZE,
  DROPDOWN_TYPE,
  ENGINE_FILTER,
  FILTER,
  FORMULA_ELEMENT_TYPE,
  FormatTypes,
  Formats,
  GLACCOUNTS_FIELDS,
  GLACCOUNTS_TITLES,
  IDENTIFIERS,
  INFO_MESSAGE,
  IS_TRANSITIONED,
  METRICS_MAPPING,
  METRIC_MAPPING_COLUMNS,
  PS_MAPPING,
  RAW_ITEMS,
  ROW_STATUS,
  SCENARIO_STATUS,
  SIZES,
  STAGING_SECTIONS,
  UPLOAD_SECTIONS,
  VECTOR_MAPPING,
  VECTOR_STAGING_ATTRIBUTES,
  costtype
} from '../../class/constants';
import { formatValHTML, formatValReact, formatValString } from "../../class/format";
import { convertPxToViewport } from '../../class/formatting';
import { alertAndLogError, getObjectAsArray, translateFilter } from '../../class/jqueries';
import { is_aN } from '../../class/number';
import { checkForSpecialChars, checkStartsWithDigit } from "../../class/string";
import { findOptionByKeyValue, generateActionToken, getSectionExists } from "../../class/utils";
import { capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey, formatToCamelNoSpace, getTranslationFile, logoutIfUnauthenticated, parseBoolean, tryParse } from '../../class/utils.js';
import { lang } from '../../language/messages_en';
import Button from '../../newComponents/Button.js';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator.js';
import DropDown from '../../newComponents/DropDown.js';
import Input from '../../newComponents/Input.js';
import Modal from '../../newComponents/Modal.js';
import RadioButton from '../../newComponents/RadioButton.js';
import SwitchToggle from '../../newComponents/SwitchToggle.js';
import EngineFilterDialog from '../filter/EngineFilterDialog.js';
import EngineFilterModal from '../filter/EngineFilterModal';
import ColumnFormula from './ColumnFormula';
import ControlButtons from './ControlButtons';
import { EngineFilter } from './EngineFilter';
import ExceptionDriver from './ExceptionDriver';
import FormulaDrop from './FormulaDrop';
import MetricConfiguration from './MetricConfiguration.js';
import VectorIdentifier from './VectorIdentifier.js';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../../class/networkUtils.js';

const $ = require('jquery');
const MESSAGES = getTranslationFile();


const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/DataModeling";
const CHECK_TITLE = "";
const checkboxCellObject = {
    title: CHECK_TITLE,
    visible: true,
    headerSort: false,
    width: convertPxToViewport(50)
}

const COST_CENTER_STATE = "PI_Cost_center_State";
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _leadingId = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _leadingCostKey = PS_MAPPING.FIELDS.LEADING_COSTKEY;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _name = PS_MAPPING.FIELDS.NAME;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _mapFormula = PS_MAPPING.FIELDS.MAP_FORMULA;
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const _matchedSuffix = MESSAGES.pss_map_exception.suffixes.matched;
const _driverType = "driver_type";
const _metric = "metric";

const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _fileType = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _value = ENGINE_FILTER.KEYS.VALUES;
const _rowPosition = ENGINE_FILTER.KEYS.ROW_POSITION;
const _paranthesis_before = ENGINE_FILTER.KEYS.PARENTHESIS_BEFORE;
const _paranthesis_after = ENGINE_FILTER.KEYS.PARENTHESIS_AFTER;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;

const _eq = MESSAGES.ui_filter.dropdowns.engine_filter_functions.equals.value;
const _neq = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_equals.value;
const _empty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
const _ct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.contains.value;
const _nct = MESSAGES.ui_filter.dropdowns.engine_filter_functions.not_contains.value;

const NONE = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
const TRANSACTION = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;


const tableId = "glAccountsTable";
const cost_center= "COST CENTER";
const value_column= "VALUE";

const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;

var defaultCount = "";
const ASSIGNED_COMB_PS_NAME_COL = {
    title: GLACCOUNTS_TITLES.NAME,
    field: GLACCOUNTS_FIELDS.NAME,
    paddingTop: convertPxToViewport(10),
    widthGrow: 2,
    format_type: FormatTypes.TEXT,
}

const vector_type = {
    historical: "Historical",
    noAlteration: "NoAlteration"
}

var GL_ACCOUNTS_COLUMNS = [
    {   
        title: GLACCOUNTS_TITLES.COMBINATION,
        field: GLACCOUNTS_FIELDS.COMBINATION,
        format_type: FormatTypes.TEXT,
        widthGrow: 2,
        widthShrink: 0
    },
    {
        title: GLACCOUNTS_TITLES.AMOUNT,
        field: GLACCOUNTS_FIELDS.AMOUNT,
        paddingTop: convertPxToViewport(10),
        format_type: FormatTypes.AMOUNT,
        widthGrow: 1,
    },
    {
        title: GLACCOUNTS_TITLES.COUNT,
        field: GLACCOUNTS_FIELDS.COUNT,
        format_type: FormatTypes.NUMERIC,
        widthGrow: 1,
    }
];

var METRIC_MAPPING_TABLE_COLUMNS = [
    { 
        title: METRIC_MAPPING_COLUMNS.TITLES.VECTOR_KEY,
        field: METRIC_MAPPING_COLUMNS.FIELDS.VECTOR_KEY,
        format_type: FormatTypes.TEXT
    },
    {
        title: METRIC_MAPPING_COLUMNS.TITLES.COST_CENTER_KEY,
        field: METRIC_MAPPING_COLUMNS.FIELDS.COST_CENTER_KEY,
        format_type: FormatTypes.TEXT
    },
    {
        title: METRIC_MAPPING_COLUMNS.TITLES.AMOUNT,
        field: METRIC_MAPPING_COLUMNS.FIELDS.AMOUNT,
        format_type: FormatTypes.AMOUNT,
        paddingTop: convertPxToViewport(10),
    }

]

/**
 * Mapping window in Engine UI
 * @author [Sarah Farjallah]
 */
class GLAccountList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profitStackFields:[],
            profitStackOriginalFields: [],
            GLAccounts:[],
            selectedOptions: [],
            mappedLines: [],
            periodName:"",
            toBeDeleted: [],
            amount: [],
            GLAccountsData: [],
            showFilter: false,
            showAncillaryFilter : false,
            filter: "",
            ancillaryFilter: "",
            amountSum: [],
            currentFilter: "",
            exclusionFilter: "",
            accountTypes: [{
                label: ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION, value: ACCOUNT_AMOUNT_FIELDS.EXCLUDED_COMBINATION
            },{
                label: ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, value: ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION
            },{
                label: ACCOUNT_AMOUNT_TITLES.UNASSIGNED_COMBINATION, value: ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION
            }],
            ancillaryFiles: [],
            mappedMetric: {},
            ancillaryFileColumns: [],
            excludedFieldsCombination: [],
            chosenMapExceptionValue: NONE,
            ancillaryFile: "",
            changed: false,
            assignedAccount: 0,
            assignedAmount: 0,
            conditionsNumber: 0,
            isAdvanced: this.props.isAdvanced ? this.props.isAdvanced : false,
            filterRowRefs: [],
            useOutsideFilter: false,
            profitStackLines:[],
            cost_term_id: this.props.costTerm,
            mappedMetric: this.props.mappedMetric,
            calculatedPslAmount: 0,
            hasRowCC:this.props.rowDataCC,
            toggleDisabled:false,
        };
        // this line is outside the object to use the accountTypes attribute
        this.state.chosenAccountType = this.props.isManageExclusions ? this.state.accountTypes[0] : this.state.accountTypes[1];
        this.setChosenTimePeriod = this.setChosenTimePeriod.bind(this);
        this.hideGLAccount = this.hideGLAccount.bind(this);
        this.getGLAccounts = this.getGLAccounts.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.calculateAssignedValuesSum = this.calculateAssignedValuesSum.bind(this);
        this.saveMappedLine = this.saveMappedLine.bind(this);
        this.saveMappedCalculatedPSL = this.saveMappedCalculatedPSL.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.onChangeExcludedAccount = this.onChangeExcludedAccount.bind(this);
        this.setGLAccountsData = this.setGLAccountsData.bind(this);
        this.onChangeMetricAttribute = this.onChangeMetricAttribute.bind(this);
        this.saveMapping = this.saveMapping.bind(this);
        this.getRawFileFieldNames = this.getRawFileFieldNames.bind(this);
        this.getAllFileTypeColumns = this.getAllFileTypeColumns.bind(this);
        this.onChangeExceptionDriver = this.onChangeExceptionDriver.bind(this);
        this.setChosenAncillaryFile = this.setChosenAncillaryFile.bind(this);
        this.parseMapExceptionQuery = this.parseMapExceptionQuery.bind(this);
        this.changesMade = this.changesMade.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.updateVectorAttribute = this.updateVectorAttribute.bind(this);
        this.setVectorMapping = this.setVectorMapping.bind(this);
        this.updateOtherIdentifiers = this.updateOtherIdentifiers.bind(this);
        this.checkToFetchNewFields = this.checkToFetchNewFields.bind(this);
        this.addCheckRowFormatter = this.addCheckRowFormatter.bind(this);
        this.checkAllLines = this.checkAllLines.bind(this);
        this.checkColumn = this.checkColumn.bind(this);
        this.showFilterModal = this.showFilterModal.bind(this);
        this.getMetricDataValidation = this.getMetricDataValidation.bind(this);
        this.checkSameCombinations = this.checkSameCombinations.bind(this);
        this.getColumnValues = this.getColumnValues.bind(this);
        this.changeFilterRow = this.changeFilterRow.bind(this);
        this.unmount = this.unmount.bind(this);
        this.validateAdvancedFilter = this.validateAdvancedFilter.bind(this);
        this.deleteConditionRow = this.deleteConditionRow.bind(this);
        this.setIndexFilter = this.setIndexFilter.bind(this);
        this.removeAddedFilterAttributes = this.removeAddedFilterAttributes.bind(this);
        this.clearDropDowns = this.clearDropDowns.bind(this);
        this.removeFilterRow = this.removeFilterRow.bind(this);
        this.validatePKsIntersection = this.validatePKsIntersection.bind(this);
        this.validatePKsIntersectionMetric = this.validatePKsIntersectionMetric.bind(this);
        this.validateIntersectionFilterMetric = this.validateIntersectionFilterMetric.bind(this);
        this.validateIntersectionFilter = this.validateIntersectionFilter.bind(this);
        this.callSaveMappedAncillary = this.callSaveMappedAncillary.bind(this);
        this.onChangeCostTerm = this.onChangeCostTerm.bind(this);
        this.parseAmount = this.parseAmount.bind(this);
        this.getMappingTableData = this.getMappingTableData.bind(this);
        this.showAdvancedFilter = this.showAdvancedFilter.bind(this);
        this.discardFilter = this.discardFilter.bind(this);
        this.setFilterAttrOptions = this.setFilterAttrOptions.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
        this.updateFiles = this.updateFiles.bind(this);
        this.finishTheRemoval = this.finishTheRemoval.bind(this);
        this.checkForCostCenter = this.checkForCostCenter.bind(this);
        this.updateConfigFileCC = this.updateConfigFileCC.bind(this);
        this.handleMetricRows = this.handleMetricRows.bind(this);
        this.calculateAmountOfCalculatedLine = this.calculateAmountOfCalculatedLine.bind(this);
        this.updateCalcPslAmount = this.updateCalcPslAmount.bind(this);
        this.parseMetricAmount = this.parseMetricAmount.bind(this);
        this.appendToFormula = this.appendToFormula.bind(this);
        this.updateFormula = this.updateFormula.bind(this);
        this.updateFields = this.updateFields.bind(this);

    //component variables
        this.isMetricMapping = false;
        this.isVectorMapping = false;
        this.isCalculatedCols = false;
        this.isProfitStackMapping = false;
        this.isPSStandard = false;
        this.isPSCalculated = false;
        this.isPSAttribute = false;
        this.isExclusions = false;
        this.filter= [];

        //this should be deleted, written here just to keep the structure in mind
        // this.vectorMappingObj = {
        //     [VECTOR_MAPPING.ATTR.VECTOR_NAME]: 'Customer',
        //     [VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID]: '12',
        //     [VECTOR_MAPPING.ATTR.KEY_VALUE]: [{value:'CustomerFirstKey', type:'field'},
        //                 {value:'-', type:'connector'},
        //                 {value:'CustomerLastKey', type:'field'},
        //     ],
        //     [VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID]: '15',
        //     [VECTOR_MAPPING.ATTR.NAME_VALUE]: [{value:'CustomerFirstName', type:'field'},
        //                 {value:'-', type:'connector'},
        //                 {value:'CustomerLastName', type:'field'},
        //     ],
        //     [VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID]: '15',
        //     [VECTOR_MAPPING.ATTR.NUMBER_VALUE]: [{value:'CustomerFirstNumber', type:'field'},
        //                 {value:'-', type:'connector'},
        //                 {value:'CustomerLastNumber', type:'field'},
        //     ],
        // };
        this.vectorMappingObj = {};
        this.isAddConditionEnabled = false;
    }

    getSelectedFieldsCombination(callback) {
        var data = this.props.mappedLines;
        var fieldsCombination = [];
        var filter = "";
        let mappedLine = data.filter(e=> e[PS_MAPPING.FIELDS.COST_KEY] === this.props.pslLine[PS_MAPPING.FIELDS.COST_KEY])[0];
        fieldsCombination = mappedLine ? copyObjectValues(mappedLine[PS_MAPPING.FIELDS.FIELDS_COMBINATIONS]) : "";
        filter = mappedLine ? mappedLine[PS_MAPPING.FIELDS.FILTER] : "";

        this.setState({
            selectedFieldsCombination: fieldsCombination,
            filter: filter
        },function(){
            callback();
        })
    }

    saveMapping(mappedMetric) {
        var _this = this;
        if (this.isProfitStackMapping) {
            if (!this.props.isManageExclusions) {
                this.saveMappedLine();
                return;
            } else if (this.state.chosenAccountType.value !== ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION) {
                // in case of unassigned get the checked, else in case of excluded get the unchecked
                var isChecked = this.state.chosenAccountType.value === ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION ? "true" : "false";
                var excludedFieldsCombination = this.tabulator.getData().filter(el => el.checked === isChecked).map(function (el) { return el.combination });
                if (excludedFieldsCombination.length > 0) {
                    if (this.props.scenarioStatus === SCENARIO_STATUS.SANDBOX) {
                        this.saveMapExclusions(excludedFieldsCombination, false);
                        defaultCount = "";
                    } else {
                        this.props.callShowScenarioPopUp("", excludedFieldsCombination, this.state.chosenAccountType.value);
                    }
                }
            }
        } else if (this.isMetricMapping) {
            this.isDuplicateName = false;
            var metrics = this.props.metricFields;
            var editedMetric = mappedMetric;
            let metricIndex = editedMetric?.index;
            var metricNameField = $("#mapped-metric-name").val();
            var formattedMetricValue = formatToCamelNoSpace(metricNameField);
            editedMetric[METRICS_MAPPING.FIELDS.METRIC] = formattedMetricValue;
            var metrics = this.props.metricFields;
            this.isDuplicateName = false;
            var filter = editedMetric[METRICS_MAPPING.FIELDS.FILTER] !== "" ?
                JSON.parse(editedMetric[METRICS_MAPPING.FIELDS.FILTER]).filter : [];
            if (this.isNameValid && this.isNameValid !== "") {
                this.props.setOpenWarningDialog(true, this.isNameValid);
                return;
            }

            //Exclude mapped metric from validation
            metrics = metrics.filter(e => e.index !== metricIndex);

            for (var e in metrics) {
                if (is_aN(Number(e))) {
                    if (metrics[e].metric_display_name.toLowerCase() === formattedMetricValue.toLowerCase() && metrics[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                        this.isDuplicateName = true;
                        break;
                    }
                }
            }

            if (this.isDuplicateName) {
                this.props.setOpenWarningDialog(true, "A metric with the same name already exists.");
                return;
            }

            if (editedMetric[METRICS_MAPPING.FIELDS.VECTOR] === '' || $("#mapped-metric-name").val().trim() === '') {
              this.props.setOpenWarningDialog(true, MESSAGES.mandatory_vectors);
                return;
            }

            var metricConfigurationRows = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration;
            var results = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].result;
            var conditions = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].conditions;
            var vectorColumns = [];
            for (var j = 0; j < metricConfigurationRows.length; j++) {
                let missingInput = false;
                metricConfigurationRows[j][RAW_ITEMS.TYPE_NAME] = metricConfigurationRows[j][RAW_ITEMS.TYPE_NAME] || UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA;
                if (metricConfigurationRows[j][RAW_ITEMS.SUBTYPE_NAME] !== "" && metricConfigurationRows[j][RAW_ITEMS.FIELD_NAME] === ""){
                    missingInput = true;    //if user filled file name without column name
                }

                if(![UPLOAD_SECTIONS.FIELDS.MASTER_DATA, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA].includes(metricConfigurationRows[j][RAW_ITEMS.TYPE_NAME])
                    && metricConfigurationRows[j][RAW_ITEMS.SUBTYPE_NAME] !== _calcCols
                    && !metricConfigurationRows[j]["vector-column"] && !vectorColumns.includes(metricConfigurationRows[j][RAW_ITEMS.TYPE_NAME])) {     //if user filled file name as MD or TD without a vector column
                        missingInput = true;
                }
                vectorColumns.push(metricConfigurationRows[j][RAW_ITEMS.TYPE_NAME]);
                if(missingInput) {
                    this.props.setOpenWarningDialog(true, MESSAGES.mandatory_vectors);
                    return;
                }
            }
            
            for (var i in conditions) {
                if (conditions[i].filters.length > 0 ) {
                    for (var elt in conditions[i].filters) {
                        if (conditions[i].filters.length === 0 || conditions[i].filters[elt][ENGINE_FILTER.KEYS.FILE] === "" || conditions[i].filters[elt][ENGINE_FILTER.KEYS.COLUMN] === "" ||
                            (conditions[i].filters[elt]["values"] && conditions[i].filters[elt]["values"].length === 0 && ![_empty, _nempty].includes(conditions[i].filters[elt][ENGINE_FILTER.KEYS.FUNCTION])) ||
                            !conditions[i].filters[elt][ENGINE_FILTER.KEYS.FILE] || !conditions[i].filters[elt][ENGINE_FILTER.KEYS.COLUMN] ||
                            !conditions[i].filters[elt]["values"]) {
                            this.props.setOpenWarningDialog(true, MESSAGES.empty_filter_dropdown);
                            return false;
                        }
                    }  
                } else {
                    this.props.setOpenWarningDialog(true, MESSAGES.empty_filter_dropdown);
                    return false;
                }
            }
            editedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].conditions= conditions;
            editedMetric[METRICS_MAPPING.FIELDS.FILTER] = "{\"filter\":"+ JSON.stringify(filter) +"}";
            if (results.length === 0) {
                this.props.setOpenWarningDialog(true, MESSAGES.mandatory_vectors);
                return;
            }

            //checking if we have duplicate rows and/or that all rows are used and deleting whats not used
            var usedAndNotUsedRows = _this.returnMetricRows(editedMetric);
            var unusedRows = usedAndNotUsedRows.notUsedRowsArray;
            // var duplicateRows = usedAndNotUsedRows.duplicateRows;
            // if (unusedRows.length > 0 || duplicateRows.length > 0) {

            if (unusedRows.length > 0) {
                _this.handleMetricRows(usedAndNotUsedRows);
            } else {
                var editedMetric = mappedMetric;
                var metricNameField = $("#mapped-metric-name").val();
                var formattedMetricValue = formatToCamelNoSpace(metricNameField);
                editedMetric[METRICS_MAPPING.FIELDS.METRIC] = formattedMetricValue;
                if(!editedMetric.index){
                    editedMetric.index = generateActionToken(5);
                }
                
                if (JSON.stringify(this.state.mappedMetric) !== JSON.stringify(this.state.mappedMetric_original)) {
                    //only update metric if one of its attributes changed
                    _this.props.updateMappedMetric(editedMetric);
                }
        
                _this.finishTheRemoval();
            }
        }
    }

    handleMetricRows(usedAndNotUsedRows) {
        this.metricConfRef.handleMetricRows(usedAndNotUsedRows)
    }

    finishTheRemoval() {
        this.hideGLAccount();
        this.setState({
            mappedMetric: {}
        })
    }

    returnMetricRows(mappedMetric) {
        var metricConfigurationRows = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].metricConfiguration;
        var results = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].result;
        var conditions = mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].conditions;
        var filter = mappedMetric[METRICS_MAPPING.FIELDS.FILTER];
        var filterRows = filter && filter !== "" && typeof (filter) === "string" ? tryParse(filter).filter : filter ? filter.filter : [];
        
        var duplicateRows = [];
        var totalRowsNotInUse = [];
        var totalRowsInUse = [];

        for (var i = 0; i < metricConfigurationRows.length; i++) {
            var row = metricConfigurationRows[i];
            var duplicates = metricConfigurationRows.filter(e => e[RAW_ITEMS.SUBTYPE_ID] === row[RAW_ITEMS.SUBTYPE_ID] && e[RAW_ITEMS.FIELD_NAME] === row[RAW_ITEMS.FIELD_NAME]).length;
            var addedDuplicateRows = duplicateRows.filter(e => e[RAW_ITEMS.SUBTYPE_ID] === row[RAW_ITEMS.SUBTYPE_ID] && e[RAW_ITEMS.FIELD_NAME] === row[RAW_ITEMS.FIELD_NAME]).length;
            if (duplicates > 1 && addedDuplicateRows === 0) {
                duplicateRows.push(row); 
            }

            row.rowNumber = i+1; //adding the row number to the object

            if (row[RAW_ITEMS.FIELD_NAME] !== "") {
                var used = false;

                //checking if the row data exists if any of the condition formulas
                if (conditions.length > 0) {
                    
                    for (var j = 0; j < conditions.length; j++) {
                        var formula = conditions[j].result;
                        var filtersInConditions = conditions[j].filters;

                        if (formula && formula.length > 0 && formula.filter(e => e.type === 'column' && e[RAW_ITEMS.FIELD_NAME] === row[RAW_ITEMS.FIELD_NAME] && e[RAW_ITEMS.SUBTYPE_NAME] === row[RAW_ITEMS.SUBTYPE_NAME] && Number(e.colIndex) === row.rowNumber).length > 0) {
                            used = true;
                        }

                        if (filtersInConditions && filtersInConditions.length > 0 && filtersInConditions.filter(e => e.fileType === row[RAW_ITEMS.SUBTYPE_NAME] && ![UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA, UPLOAD_SECTIONS.FIELDS.MASTER_DATA].includes(e[RAW_ITEMS.TYPE_NAME])).length > 0) {
                            used = true;
                        }
                    }
                }

                //checking if the row data exists in the final formula (formula if false: if available)
                if (results.length > 0 && results.filter(e => e.type === 'column' && e[RAW_ITEMS.FIELD_NAME] === row[RAW_ITEMS.FIELD_NAME] && e[RAW_ITEMS.SUBTYPE_NAME] === row[RAW_ITEMS.SUBTYPE_NAME] && Number(e.colIndex) === row.rowNumber).length > 0) {
                    used = true;
                }
           
                //checking if the row data exists in the metric configuration filter
                if (filterRows.length > 0 && filterRows.filter(e => e.fileType === row[RAW_ITEMS.SUBTYPE_NAME] && ![UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA, UPLOAD_SECTIONS.FIELDS.MASTER_DATA].includes(e[RAW_ITEMS.TYPE_NAME])).length > 0) {
                    used = true;
                }
           
                if (used) {
                    totalRowsInUse.push(row);
                } else {
                    totalRowsNotInUse.push(row);
                }
            }
        }
        

        return {usedRowsArray: totalRowsInUse, notUsedRowsArray: totalRowsNotInUse, duplicateRows: duplicateRows};
    }

    matchCostKey(pssFields, costKey) {
        for (var e  in pssFields) {
            if (pssFields[e][PS_MAPPING.FIELDS.COST_KEY] === costKey) {
                return true;
            } else if (pssFields[e][_children] && pssFields[e][_children].length > 0) {
                if (this.matchCostKey(pssFields[e][_children], costKey) === true) {
                    return true;
                } else {
                    continue;
                }
            }
        }
        return false;
    }

    checkIfVarOrMat(pssFields, costKey) {
        for (var e in pssFields) {
            if (pssFields[e][PS_MAPPING.FIELDS.NAME].toLowerCase() === 'net revenue' || pssFields[e][PS_MAPPING.FIELDS.NAME].toLowerCase() === 'cost of sales') {
               if(this.matchCostKey(pssFields[e][_children], costKey) === true ){
                   return true
                }else {
                    continue ;
                } 
            }
            
        }
        return false;
    }

    validateSiblings(siblings) {
        var _this = this;
        var validation = {emptyVal: false, percentagesSum: false, has0Perc: false, 
            isMappedPresent: siblings.filter(row=>row[_id] === _this.props.mappedLine[_id]).length > 0
        }
        let percSum = 0;

        for(var e in siblings) {
            let flag = false;   //use to check if any cond is true
            if (siblings[e][_isMatched] && siblings[e][_mappingException] !== NONE) {
                if(this.exclDriverRef && this.exclDriverRef.isSplitPerc) {
                    if (!is_aN(siblings[e][_amount]) || !is_aN(siblings[e][_percentage]) ||
                        !siblings[e][PS_MAPPING.FIELDS.NAME]
                        || ((!siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] || !siblings[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]) && siblings[e][_id] === this.exclDriverRef.state.leadingPssID)) {
                        validation.emptyVal = true;
                    }
                } else {
                    if (!is_aN(siblings[e][_amount]) || !siblings[e][PS_MAPPING.FIELDS.NAME] || 
                        this.exclDriverRef && this.exclDriverRef.isAncillary && (!siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] || !siblings[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME])) {
                        validation.emptyVal = true;
                    }
                }

                flag = true;
            } else if (siblings[e][_mappingException] === NONE){
                if (!siblings[e][PS_MAPPING.FIELDS.NAME]) {
                    validation.emptyVal = true;
                }
                flag = true;
            }
            if (siblings[e][_mappingException] === TRANSACTION) {
                if(!siblings[e][_calcCol] && siblings[e][_isMatched]) {
                    validation.emptyVal = true;
                }
            }

            let perc = parseFloat(siblings[e][_percentage]);
            if(flag) {
                if(perc === 0 && this.exclDriverRef.isSplitPerc) {
                    validation.has0Perc = true;
                }
                percSum += perc;
            }
        }

        if(this.exclDriverRef && this.exclDriverRef.isSplitPerc) {
            validation.percentagesSum = percSum === 100;
        } else {
            validation.percentagesSum = true;
        }
        
        return validation;
    }

    validateAssignedAmount() {
        return !!this.tabulator.getData().filter(el => el.checked === "true").length;
    }

    validateIntersectionFilter(siblings, intersectionFiltersMetric, callbackMain) {
        var files = [];
        var  filterLines = [];

        var obj = this;
        for (var e in siblings) {
            if (siblings[e][_isMatched] && siblings[e][_mappingException] === ANCILLARY && siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]) {
                if(siblings[e][_driverType] !== _metric) {
                    files.push(siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]);
                }
            }
        }
        
        for(var elt in files){
            var lines = siblings.filter(e=>e[PS_MAPPING.EXCEPTION_FIELDS.FILE] === files[elt] && e[_isMatched]);
            if (lines.length > 1) { // more than one line mapped to same file
                for (var i in lines) {
                    filterLines.push({subType:files[elt], field: lines[i][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME], filter:lines[i].ancillaryFilter});
                }
            }
            if(filterLines.length > 0)
                break;
        }
        let callback = ()=>{};
        callback = function(result) {
            if (result === "FAIL") {
                callbackMain(false,intersectionFiltersMetric);
            } else{
                callbackMain(true, intersectionFiltersMetric);
            }
        }
        if (filterLines.length === 0) {
            callbackMain(true, intersectionFiltersMetric);
        } else {
            obj.validatePKsIntersection(filterLines, callback);
        }

    }

    validateIntersectionFilterMetric(siblings, callbackMain) {
        var files = [];
        var  filterLines = [];

        var obj = this;
        for (var e in siblings) {
            if (siblings[e][_isMatched] && siblings[e][_mappingException] === ANCILLARY && siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]) {
                if(siblings[e][_driverType] === _metric) {
                    files.push(siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE])
                }
            }
        }
        
        for(var elt in files){
            var lines = siblings.filter(e=>e[PS_MAPPING.EXCEPTION_FIELDS.FILE] === files[elt] && e[_isMatched]);
            if (lines.length > 1) { // more than one line mapped to same file
                for (var i in lines) {
                    filterLines.push({subType:files[elt], field: lines[i][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME], filter:lines[i].ancillaryFilter});
                }
            }
            if(filterLines.length > 0)
                break;
        }
        let callback = ()=>{};
        callback = function(result) {
            if (result === "FAIL") {
                callbackMain(false);
            } else{
                callbackMain(true);
            }
        }
        if (filterLines.length === 0) {
            callbackMain(true);
        } else {
            obj.validatePKsIntersectionMetric(filterLines, callback);
        }

    }

    validatePKsIntersection(filterLines, callback) {
        var query = {
            action: "validatePKsIntersection",
            scenario_id: this.props.scenarioId,
            filterLines: filterLines,
            timePeriod: this.state.periodName
        }

        if(!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "validatePKsIntersection", true);
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json()
            }).then((data)=>{
                if (data && data.result) {
                    callback(data.result);
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "validatePKsIntersection", true);
            });
    }

    validatePKsIntersectionMetric(filterLines, callback) {
        var query = {
            action: "validatePKsIntersectionMetric",
            scenario_id: this.props.scenarioId,
            filterLines: filterLines,
            timePeriod: this.state.periodName
        }

        if(!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "validatePKsIntersectionMetric", true);
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json()
            }).then((data)=>{
                if (data && data.result) {
                    callback(data.result);
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "validatePKsIntersectionMetric", true);
            });
    }
    
    saveMappedLine() {
        var _this = this;
        let callback = ()=>{};

        var mappingData = _this.exclDriverRef.getUpdatedExceptionData();
        var siblings = mappingData.data;
        var removedIds = mappingData.deletedIds;
        callback = function(intersectionFilters, intersectionFiltersMetric) {
            var mappedLines = _this.props.mappedLines;
            var mappingException = _this.state.chosenMapExceptionValue;
            var fieldsCombination = _this.tabulator.getData().filter(el => el.checked === "true").map(function(el) { return el.combination});
    
            if (!intersectionFilters && intersectionFilters !== undefined ) {
                this.setInfoDialogOpen(true, MESSAGES.intersection_in_filters);
                return;
            }

            if (!intersectionFiltersMetric && intersectionFiltersMetric !== undefined ) {
                this.setInfoDialogOpen(true, MESSAGES.intersection_in_filters);
                return;
            }
            if (!_this.validateAssignedAmount()) {
                this.setInfoDialogOpen(true, MESSAGES.non_profit_stack_assigned_amount);
                return;
            }

            let siblingValidation = _this.validateSiblings(siblings);
            if (siblingValidation.emptyVal) {
                this.setInfoDialogOpen(true, MESSAGES.empty_mapped_values);
                return;
            }
            if (!siblingValidation.isMappedPresent) {
                this.setInfoDialogOpen(true, MESSAGES.mapped_line_not_present);
                return;
            }
            if (!siblingValidation.percentagesSum) {
                this.setInfoDialogOpen(true, MESSAGES.percentages_do_not_sum);
                return;
            }
            if (siblingValidation.has0Perc) {
                this.setInfoDialogOpen(true, MESSAGES.percentages_0_perc);
                return;
            }

            var isMappedLineFound = siblings.filter(s => s[_name].replace(_matchedSuffix,'') === _this.props.mappedLine[_name].replace(_matchedSuffix,''))[0];
            if(!isMappedLineFound) {
                this.setInfoDialogOpen(true, MESSAGES.mapped_line_deleted);
                return;
            }

            var leadingId = siblings[0][_leadingId];
            var leadingCostkey = siblings[0][_leadingCostKey];
            for(var e in siblings) {                
                if (siblings[e][_id] === leadingId) { // setting edit flag 
                    siblings[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    siblings[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = JSON.parse(JSON.stringify(fieldsCombination));
                } else { // changing leading id and costkey when changing from none to transaction
                    siblings[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = [];
                    siblings[e][PS_MAPPING.FIELDS.LEADING_COSTKEY] = leadingCostkey;
                    siblings[e][PS_MAPPING.FIELDS.LEADING_PSS_ID] = leadingId;
                }
                if (siblings[e][PS_MAPPING.FIELDS.COST_KEY] === _this.props.profitStackLineToMap[PS_MAPPING.FIELDS.COST_KEY]) {
                    siblings[e][PS_MAPPING.FIELDS.DESCRIPTION] = $("#metric_description").val();
                }
                siblings[e][_mappingException] = mappingException;
                siblings[e][PS_MAPPING.FIELDS.COST_TERM_ID] = _this.state.cost_term_id;
                siblings[e][_costType] = costtype.standard;
            }

            var isNewLeading = false;
            if(removedIds && removedIds.length) {
                for (var e in mappedLines) {
                    if (removedIds.includes(mappedLines[e][_id])) {
                        if(mappedLines[e][_id] === leadingId) {
                            isNewLeading = true;
                        }
                        mappedLines[e][PS_MAPPING.FIELDS.DELETED] = "true"; // setting deleted flag to true for deleted lines
                        mappedLines[e][_leadingId] = leadingId; // setting their new leadingID in case it was changed
                        mappedLines[e][_leadingCostKey] = leadingCostkey;
                        mappedLines[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = [];
                        mappedLines[e][_mappingException] = NONE;
                        mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] = "";
                        mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL] = "";
                    }
                }
            }
            // delete accrual line
            if (mappingException === TRANSACTION) {
                for (var e in mappedLines) {
                    if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] !== ""  && siblings.filter(row=>row[PS_MAPPING.FIELDS.ID] == mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID]).length > 0) {
                        mappedLines[e][PS_MAPPING.FIELDS.DELETED] = "true"; // setting deleted flag to true for deleted lines
                    }
                }
            }
        
            

            var newMappedLines = mappedLines.filter(function(el){ //removing siblings
                if((el[_leadingCostKey] !== _this.props.pslLine[_costKey] || el[_leadingCostKey] === _this.props.pslLine[_costKey] && el[PS_MAPPING.FIELDS.DELETED] === "true" )
                    && !findOptionByKeyValue(siblings, _costKey, el[_costKey])) {   //a mapped line might also be present in siblings if mapped by cost center first before being mapped to cpombinations
                    return el;
                }
            });

            var toAppend=[];
            var names =[];
            for(var e in mappedLines) {
                for (var elt in siblings) {
                    if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] == siblings[elt][PS_MAPPING.FIELDS.ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] !== "" && !names.includes(mappedLines[e].name)) {
                        toAppend.push(mappedLines[e]);
                        names.push(mappedLines[e].name);
                    }
                }
            }
            
            newMappedLines = newMappedLines.concat(siblings);
            _this.setState({
                leadingId: leadingId,
                leadingCostKey: leadingCostkey,
                mappedLines: newMappedLines,
                profitStackLines: siblings.concat(toAppend),
                removedIds: removedIds
            });
            window._profitIsleOpenModal('mappingChanges');
        }

        if(this.exclDriverRef.isTransaction) {
            callback();
        } else {
            let funcCallBack = ()=>{};
            funcCallBack = function(intersectionFiltersMetric) {
                _this.validateIntersectionFilter(siblings,intersectionFiltersMetric, callback);
            }
            _this.validateIntersectionFilterMetric(siblings, funcCallBack)
        }
    }

    saveMappedCalculatedPSL() {
        if(!this.formulaDRef.validateFormulaResult(this.calcPsl[_mapFormula])) {
            this.setInfoDialogOpen(true, MESSAGES.formula.invalid_formula);
            return;
        }
        this.calcPsl[PS_MAPPING.FIELDS.DESCRIPTION] = $("#metric_description").val();
        this.props.setMappedLines(this.calcPsl);
    }

    callSaveMappedAncillary() {
        var _this = this;
        window._profitIsleCloseModal('mappingChanges');
        if (!this.state.leadingId) {
            _this.props.setMappedLines(_this.state.mappedLines, _this.state.periodName, undefined, undefined, _this.state.removedIds);
        } else {
            _this.props.setMappedLines(_this.state.mappedLines, _this.state.periodName, _this.state.leadingId, _this.state.leadingCostKey, _this.state.removedIds);
        }
        _this.props.callTotals(undefined, false, true);
    }

    parseAmount(lines, assignedAmount, callback) {
            var _this = this;
            let periods = [_this.state.periodName];
            toggleLoader(true, "parseAmount", true);
            var query = {
                action: "parseAmount",
                mappedLines: JSON.stringify(lines),
                selectedPeriods: periods,
                scenario_id: _this.props.scenarioId,
                clientName: _this.props.clientName,
                assignedAmount: assignedAmount
            }
          setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
          fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
                .then((response)=>{    
                    if(response.status === 403) {
                        this.logout();                  
                    }
                    return response.json()})
                .then((data)=>{
                    if(data){
                        callback(data);
                    }
                }).catch((error)=>{
                    alertAndLogError(error);
                }).then(() => {
                    toggleLoader(false, "parseAmount", true);
                });
    }

    setChosenTimePeriod(e) {
        var _this = this;
        if(e !== null){
            this.setState({
                periodName: e.value
            },function(){
                if (this.isMetricMapping) {
                    this.getVectorList();
                    this.getMetricDataValidation();
                }
                if ([undefined,""].includes(this.state.filter)) {
                    this.getMappingTableData(e.value);
                } else {
                    this.getMappingTableData(e.value, translateFilter(JSON.parse(this.state.filter).filter, true), "false");
                }

                if(_this.exclDriverRef) {
                    _this.exclDriverRef.onChangeTimePeriod(this.state.periodName);
                }
            })
        }
    }

    calculateDefaultAssignedValuesSum(e) {
        var count = this.tabulator.getData().filter(el => el.checked === "true").length;
        this.state.assignedAccount = count.toString();
        defaultCount = defaultCount === ""|| defaultCount === "0" ? count.toString() : defaultCount;
        var sum = 0;
        this.tabulator.getData().filter(el => el.checked === "true").map(el => sum += parseFloat(el.amount));

        var count = this.tabulator.getData().filter(el => el.checked === "true").length;
        e &&  e.target && e.target.checked ? this.changesMade(true, count) : this.changesMade(false, count);

        $("#assignedAccount").text(this.state.assignedAccount);
        this.state.assignedAmount = formatValHTML(sum, FormatTypes.AMOUNT);
        if(this.exclDriverRef) {
            this.exclDriverRef.setAssignedAmount(sum, count);
        }
        $("#assignedAmount").html(this.state.assignedAmount);
        if(sum < 0) {
            $("#assignedAmount").addClass("red");
        } else {
            $("#assignedAmount").removeClass("red");
        }
    }

    calculateAssignedValuesSum(e, cell) {
        cell.getData().checked = e.target.checked ? "true" : "false";

        var count = this.tabulator.getData().filter(el => el.checked === "true").length;
        this.state.assignedAccount = count;
        e.target.checked ? this.changesMade(true, count) : this.changesMade(false, count);

        var sum = 0;
        var count = 0;
        this.tabulator.getData().filter(el => el.checked === "true").map(el => sum += parseFloat(el.amount));
        this.tabulator.getData().filter(el => el.checked === "true").map(el => count += 1);

        if (sum === 0 || count === 0) {
            $("#main_checkbox").prop("checked",false);
            $("#main_checkbox").prop("indeterminate",false);
        } else if (count < this.tabulator.getData().length) {
            $("#main_checkbox").prop("indeterminate",true);
        } else if (count === this.tabulator.getData().length) {
            $("#main_checkbox").prop("checked",true);
        }
        $("#assignedAccount").text(this.state.assignedAccount);
        this.state.assignedAmount = formatValHTML(sum, FormatTypes.AMOUNT);
        if(this.exclDriverRef) {
            this.exclDriverRef.setAssignedAmount(sum, count);
        }

        $("#assignedAmount").html(this.state.assignedAmount);
        if(sum < 0) {
            $("#assignedAmount").addClass("red");
        } else{
            $("#assignedAmount").removeClass("red");
        }
    }

    checkSameCombinations(arr1, arr2) {
        for (var e in arr1) {
            if (!arr2.includes(arr1[e]["combination"])) {
                return false;
            }
        }
        return true;
    }
    //changing the changed state to disable APPLY in mapping a profit stack mapping line when no changes have been made
    changesMade(val, count) {
        var obj = this;
        var checked = this.tabulator.getData().filter(el => el.checked === "true");
        var originalChecked = obj.props.pslLine.fieldsCombination;
        $("#submit_GL .btn_primary").removeClass("disabled");
        $("#submit_GL #submit-btn").removeClass("disabled");
    }

    /**
	 * This block of commented code is for PI-6264, please keep commented
	 */
    // getCombinationGLLines(rowData) {
    //     var obj = this;
    //     var query = {
    //         action: "getCombinationGLLines",
    //         machine_name: this.props.machineName,
    //         token: this.props.idToken,
    //         clientId: this.props.clientId,
    //         scenario_id: this.props.scenarioId,
    //         project_id: this.props.projectId,
    //         dbDataSet: this.props.dbDataSet,
    //         tablePrefix: this.props.tablePrefix,
    //         requiredCombination: rowData[GLACCOUNTS_FIELDS.COMBINATION]
    //     }

    //     toggleLoader(true, "getCombinationGLLines", true);      //show small loading div
    //     fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
    //         .then((response)=>{
    //             logoutIfUnauthenticated(response, this);
    //             return response.json()
    //         }).then((data)=>{
    //             this.setState({
    //                 combinationGLLines: data.data
    //             }, function(){
    //                 obj.combinationsTabulator.setColumns(data.columns);
    //                 obj.combinationsTabulator.setData(data.data);
    //             });
    //         }).catch((error)=>{
    //             alertAndLogError(error);
    //             toggleLoader(false, "getCombinationGLLines", true);
    //         });
    // }

    checkColumn() {
        var data = this.tabulator.getData();
        var countAllRows = 0;

        data.map(function (item) {
            if (item["checked"] && item["checked"] === "true") {
                countAllRows++;
            }
        });

        if (countAllRows === data.length) {
            $("#main_checkbox").prop('indeterminate', false);
            $("#main_checkbox").prop('checked', true);
        } else if (countAllRows === 0) {
            $("#main_checkbox").prop('checked', false);
            $("#main_checkbox").prop('indeterminate', false);
        } else if (countAllRows < data.length) {
            $("#main_checkbox").prop('checked', false);
            $("#main_checkbox").prop('indeterminate', true);
        }
    }

    getColumnFormatter(colTitle) {
        var obj = this;
		var columnFormatter;		
        switch (colTitle) {
			case CHECK_TITLE:
			columnFormatter = function(cell, formatterParams) {
                if(obj.state.chosenAccountType.value !== ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION || !obj.props.isManageExclusions) {
                    var checkbox = createCheckbox();
                    checkbox.name='chosenEntity';
                    checkbox.classList.add('chosenEntity');
                    checkbox.checked = cell.getData()["checked"] === "true";
                    obj.checkColumn();
                    checkbox.onchange = (e) => {
                        obj.state.GLAccountsData.map(function(el){ if (el[GLACCOUNTS_FIELDS.COMBINATION] ===cell.getData()[GLACCOUNTS_FIELDS.COMBINATION]) el.checked = (cell.getData()["checked"] === "true").toString() });
                        obj.calculateAssignedValuesSum(e, cell);
                    };

                    return checkbox;
                }
            };
            break;

            /**
             * This block of commented code is for PI-6264, please keep commented
             */
            // case GLACCOUNTS_TITLES.COUNT:
            //     columnFormatter = function(cell) {
            //         var anchor = document.createElement("a");
            //         anchor.style.cursor = "pointer";
            //         anchor.textContent = cell.getValue();
            //         anchor.onclick = ()=>obj.getCombinationGLLines(cell.getRow().getData());

            //         return anchor;
            //     }
            //     break;
            case GLACCOUNTS_TITLES.AMOUNT:
                columnFormatter = function(cell) {
                    var p = document.createElement("p");
                    p.innerHTML = formatValHTML(cell.getValue(), FormatTypes.AMOUNT);
                    
                    if (Number(cell.getValue()) < 0) {
                        p.classList.add("red");
                    }

                    return p;
                }
                break;
            
			default:
			columnFormatter = function(cell, formatterParams) {
				return cell.getValue();
			}
			break;
		}
		
		return columnFormatter;
    }
    
    getAncillaryFiles(fromMount) {
        var obj = this;
        var query = {
            action: "getAncillaryFiles",
            scenario_id: this.props.scenarioId
        }

        if(!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "getAncillaryFiles", this.isMetricMapping ? false : true);
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json()
            }).then((data)=>{

                let ancillaryFiles = [];
                data.data.map(function(item) {
                    ancillaryFiles.push({label: item[METRICS_MAPPING.FIELDS.RAW_FILE_DISPLAY_SUBTYPE], value: item[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE], raw_file_subtype_id: item[RAW_ITEMS.SUBTYPE_ID], type:""});
                });
                
                this.setState({
                    ancillaryFiles: ancillaryFiles,
                    ancillaryColumns: data.columns
                },function(){
                    if(fromMount) {
                        if(this.isMetricMapping && this.props.mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE]) {
                            var columns = obj.props.allTypeColumns && obj.props.allTypeColumns.length > 0 ? obj.props.allTypeColumns.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === obj.props.mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE_ID]) : [];
                            var costCenterCol = columns.filter(el=>el.name === cost_center).length > 0;
                            var _value = columns.filter(el=>el.name === value_column).length > 0 ? columns.filter(el=>el.name === value_column)[0] : "";
                            
                            this.updateConfigFileCC(costCenterCol);
                            $("#formulaValue").val(_value[RAW_ITEMS.FIELD_NAME]);
                            $("#dots_loader").removeClass("uk-display-flex");
                            $("#dots_loader").css('display','none');
                        }
                    }
                });
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "getAncillaryFiles", true);
            });
    }

    clearDropDowns(conditionIndex, index, onlyValues) {

        if (!onlyValues) {
            this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_functionOptions] = [];
            this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_function] = "";
            this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_fieldDataType] =""; //STRING OR NUMERIC
            this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_column] = "";
            this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_columnOptions] = [];
            
            this.filter[conditionIndex][index]["operator"] =  "";
            this.filter[conditionIndex][index]["function"] =  "";
            this.filter[conditionIndex][index]["column"] =  "";
            this.filter[conditionIndex][index]["column_options"] =  "";
        }
        
        this.filter[conditionIndex][index]["value"] =  [{}];
        this.filter[conditionIndex][index]["value_options"] = [];
        this.filter[conditionIndex][index]["values"] = [];
        this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj["values"] = [];
        this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_valueOptions] = [];
    }

    getRawFileFieldNames() {
        var obj = this;
        var query = {
            action: "getRawFileFieldNames",
            scenario_id: this.props.scenarioId
        }

        if (!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "getRawFileFieldNames", true);
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json()
            }).then((data)=>{
                let fields = [];
                data.data.map(function(item) {
                    if (item[IS_TRANSITIONED]) {
                        fields.push({ 
                            label: item[DEFINE_FIELDS.DATA_FIELD],
                            value: item[DEFINE_FIELDS.DATA_FIELD].replace(/ /g, "_"),
                            type: item[DEFINE_FIELDS.DATA_TYPE_FIELD]
                        });
                    }
                    
                });
                this.setState({
                    fields: fields
                });

            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "getRawFileFieldNames", true);
            });
    }
    
    changeFilterRow(object, attribute, index, conditionIndex, opt) {

        switch (attribute){
            case _fileType:
                this.state.useOutsideFilter = false;
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_fileType] = opt.value;
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_rowPosition] = index;
                if (this.filter[conditionIndex] && !this.filter[conditionIndex][index]) {
                    this.filter[conditionIndex][index] = {fileType:opt.value, raw_file_subtype_id: opt.raw_file_subtype_id, file: opt.value, type: "column", file_options: this.state.engineFiles} 
                } else {
                    this.filter[conditionIndex][index]["raw_file_subtype_id"] =  opt.raw_file_subtype_id;
                    this.filter[conditionIndex][index]["file"] = opt.value;
                    this.filter[conditionIndex][index]["type"] = "column";
                    this.filter[conditionIndex][index]["file_options"] =  this.state.engineFiles;
                    this.filter[conditionIndex][index]["fileType"] = opt.value;
                    this.clearDropDowns(conditionIndex, index);
                }
                this.getAllFileTypeColumns({section:"", subSection:opt.value, raw_file_subtype_id: opt.raw_file_subtype_id}, undefined, conditionIndex, undefined, undefined,  index);
                this.forceUpdate();
            break;

            case _column:
                this.state["column_"+conditionIndex] = opt.value;
                if (!this.filter[conditionIndex][index]) {
                    this.filter[conditionIndex][index] = {column: opt.value} ;
                } else {
                    this.filter[conditionIndex][index]["column"] = opt.value;
                }
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_fieldDataType] = opt.field_data_type; //STRING OR NUMERIC
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_column] = opt.value;
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.isLoading = false;
                var functionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, opt.field_data_type.toLowerCase(), "value_type");
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_functionOptions] = functionOptions;
                this.filter[conditionIndex][index]["function_options"] =  functionOptions;
                this.filter[conditionIndex][index]["field_data_type"] = opt.field_data_type.toLowerCase();
                this.clearDropDowns(conditionIndex, index, true);
                this.forceUpdate();
            break;
            case _function:
                if (!this.filter[conditionIndex][index]) {
                    this.filter[conditionIndex][index] = {operator: opt.value} ;
                    this.filter[conditionIndex][index] = {function: opt.value} ;
                }else {
                    this.filter[conditionIndex][index]["operator"] =  opt.value;
                    this.filter[conditionIndex][index]["function"] =  opt.value;
                }
                if ([_empty, _nempty].includes(opt.value) === -1) {
                    this.filter[conditionIndex][index]["value"] =  [];
                }else {
                    if (!([_eq, _neq] && this.filter[conditionIndex][index]["field_data_type"] === "NUMERIC")){
                        this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.isLoading = true;
                        this.getColumnValues(this.filter[conditionIndex][index]["raw_file_subtype_id"], (opt.field_data_type || opt.value_type.toUpperCase()), this.filter[conditionIndex][index]["column"], conditionIndex, opt.value, index, "");
                    }
                }
                this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_function] = opt.value;
            break;
            case _logicalOperator:
                // if (!this.filter[conditionIndex][index+1]) {
                //     this.filter[conditionIndex][index+1] = {
                //         [_logicalOperator]: opt.value, 
                //         [_paranthesis_after]: ")",
                //         [_paranthesis_before]: opt.value === "OR" ? "(" : ""
                //     };
                // } else {
                //     this.filter[conditionIndex][index+1][_logicalOperator] = opt.value;
                //     this.filter[conditionIndex][index+1][_paranthesis_after] = ")";
                //     this.filter[conditionIndex][index+1][_paranthesis_before] = opt.value === "OR" ? "(" : "";
                // }
                if (!this.filter[conditionIndex][index]) {
                    this.filter[conditionIndex][index] = {
                        [_paranthesis_before]: "(",
                        [_paranthesis_after]: opt.value === "OR" ? ")" : ""
                    }
                } else {
                    this.filter[conditionIndex][index][_paranthesis_before] = "(";
                    this.filter[conditionIndex][index][_paranthesis_after] =  opt.value === "OR" ? ")" : "";
                }
                this.forceUpdate();
                break;
            case _value:
                if(Array.isArray(opt)) {
                    for (var i in opt){
                        if(opt[i].action === "set-value" && opt[i].value.includes(",")){
                            var values = opt[i].value.split(",");
                            for(var v in values){
                                opt.push({value:values[v],label:values[v],action:"input-value"})
                            }
                            opt.splice(i,1);
                        }
                    }
                    
                    var uniq = new Set(opt.map(e => JSON.stringify(e)));
                    opt = Array.from(uniq).map(e => JSON.parse(e));
                    if(opt instanceof Node) {    //it's an input
                        opt = [{value: $(opt).val()}];
                    }
                    this.state["value_"+conditionIndex] = opt;
                    if (!this.filter[conditionIndex][index]) {
                        this.filter[conditionIndex][index] = {value: opt};
                    } else {
                        this.filter[conditionIndex][index]["value"] = opt;
                        this.filter[conditionIndex][index]["values"] = opt;
                    }
                    this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_value] = opt;
                    this.forceUpdate();
                } else if(opt instanceof Node) {
                    opt = [{value: $(opt).val()}];
                    this.state.filterRowRefs[conditionIndex].current.state.filterRefs[index].ref.current.filterObj[_value] = opt;
                    this.forceUpdate();
                }
               
                break;
            default : break;
        }

    }

    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue, tempExceptionData) {
        var _this = this;
        var row = this.filterModalRef && this.filterModalRef.filterDialRef && this.filterModalRef.filterDialRef.state.filterRefs &&
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj ? this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj : {};

        let callback = ()=>{};        
        switch (attribute){
            case _column:
                var functionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                _this.setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);
                callback = function(values) {
                    //setting the value options
                    _this.setFilterAttrOptions(rowIndex, _valueOptions, values);
                    row[_valueOptions] = values;
                }
                if (row && row[_fieldDataType].toLowerCase() !== Formats.Numeric.toLowerCase()) {
                    var subTypeId = row[RAW_ITEMS.SUBTYPE_ID];
                    var type = ""
                    var value = row[_column];
                    let valueModified = row[_column].replace(FILTER.VALUES.FIELD.KEY,"").replace(FILTER.VALUES.FIELD.NAME,"").replace(FILTER.VALUES.FIELD.NUMBER,"");                   let vectorId = "";
                    let filterAttribute = "";
                    let hiddenVectors = _this.props.hiddenVectors;
                    if (row[RAW_ITEMS.SUBTYPE_ID] === "vectors") {
                        subTypeId = _this.props.vectorList.filter(el=>el.value === row.column)[0][RAW_ITEMS.SUBTYPE_ID];
                        value = _this.props.vectorList.filter(el=>el.value === row.column)[0].usedValue;
                        type = "vectors"
                        vectorId = _this.props.vectorOptions.filter(el=>el.value === valueModified).length > 0 ? _this.props.vectorOptions.filter(el=>el.value === valueModified)[0][VECTOR_STAGING_ATTRIBUTES.ID] : hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === valueModified).length > 0 ? hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === valueModified)[0][VECTOR_STAGING_ATTRIBUTES.ID]: "0";
                            if(value.endsWith(FILTER.VALUES.FIELD.KEY)){
                                filterAttribute = FILTER.VALUES.FIELD.KEY;
                            } else if(value.endsWith(FILTER.VALUES.FIELD.NAME)){
                                filterAttribute = FILTER.VALUES.FIELD.NAME;
                            } else {
                               filterAttribute = FILTER.VALUES.FIELD.NUMBER;
                           }
                    } else if (row[RAW_ITEMS.SUBTYPE_ID] === _calcCols) {
                        type = _calcCols;
                    }
                    _this.getColumnValues(subTypeId, row[_fieldDataType], value, rowIndex, row[_function], rowIndex, "", callback, type,vectorId,filterAttribute);
                }
                break;
            case _fileType:
                var options = _this.props.allTypeColumns;
                if (valueObj.value === "vectors") {
                    var cols = _this.props.vectorList;
                } else if (valueObj.value === _calcCols) {
                    var cols = _this.props.calculatedColumns;
                } else {
                    var options = _this.props.allTypeColumns.filter(row=>row[RAW_ITEMS.SUBTYPE_ID] === valueObj[RAW_ITEMS.SUBTYPE_ID]);
                    var cols = [];
                    for (var e in options) {
                        cols.push({label: options[e][RAW_ITEMS.FIELD_NAME], value: options[e][RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_NAME]: options[e][RAW_ITEMS.SUBTYPE_NAME],
                            [RAW_ITEMS.SUBTYPE_ID]: options[e][RAW_ITEMS.SUBTYPE_ID], field_data_type: options[e][RAW_ITEMS.FIELD_DATA_TYPE],
                            [RAW_ITEMS.DATA_FIELD]: options[e][RAW_ITEMS.DATA_FIELD]
                        })
                    }
                }
                _this.setFilterAttrOptions(rowIndex, _columnOptions, cols);
                break;

            case _logicalOperator: // to enable column drop down when adding new row
                _this.setFilterAttrOptions(rowIndex+1, _fileOptions, row[_fileOptions], row[_rawFileSubtypeId]);
                _this.setFilterAttrOptions(rowIndex+1, _columnOptions, row[_columnOptions]);
        }
    }


    getColumnValues(subtypeId, fieldDataType, value, index, functionValue, rowIndex, inputValue, callback, type,vectorId,filterAttribute) {
        let obj = this;
        var isDisabled = [_ct, _nct].indexOf(functionValue) > -1 ? true : false;

        obj.isAddConditionEnabled = false;
        // only send the request once for the same subtype and column
        if(obj.state[subtypeId+"_"+value+"_values"] !== undefined) {
            if(obj.state[subtypeId+"_"+value+"_values"] !== "request_pending") {
                if(callback && typeof callback === "function"){
                    callback(obj.state[subtypeId+"_"+value+"_values"]);
                }
                if(obj.state.filterRowRefs.length > 0 && obj.state.filterRowRefs[index].current){
                    obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.filterObj[_valueOptions] = obj.state[subtypeId+"_"+value+"_values"];
                    obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.isLoading = false;
                }
            }
            obj.isAddConditionEnabled = true;
            return;
        } 
        obj.state[subtypeId+"_"+value+"_values"] = "request_pending";

        var dataBody = { 
            action: "getColumnValues",
            rawFileSubTypeId: subtypeId,
            field_dataType: fieldDataType,
            attribute: value,
            input_value : inputValue ? inputValue : "",
            type: type ? type : "",
            scenario_id: obj.props.scenarioId,
        };
       
        dataBody[VECTOR_STAGING_ATTRIBUTES.ID] = vectorId;
        dataBody["filterAttribute"] = filterAttribute;
        dataBody["timePeriod"] = obj.props.periodName;
        
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = API_URL.DATA_MODELING;
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{    
                if(response.status === 403) {
                }
                return response.json()})
            .then((data)=>{
                if(data.data){
                    var values=[];
                    data.data.map(function(item, index){
                        if (![_nempty, _empty].includes(functionValue)) {
                            if (item.attribute !== "" && item.attribute !== undefined) {
                                values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                            }
                        } else {
                            values.push({label:item.attribute, value:item.attribute, action: "input-value", isDisabled: isDisabled});
                        }
                    });
                    if(callback === undefined) {
                        obj.isAddConditionEnabled = true;
                        obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.isLoading = false;
                        // if (obj.filter && obj.filter.length > 0 && obj.filter[index])  
                        //     obj.filter[index][rowIndex]["value_options"] =  values;
                        // obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.filterObj[_valueOptions] = values;
                        // obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.isLoading = false;
                        // if(Number(index)+1 === obj.filter.length && Number(rowIndex)+1 === obj.filter[index].length && obj.state.didMount) {
                        //     obj.state.didMount = false;
                        // }
                        obj.state[subtypeId+"_"+value+"_values"] = values;
                        if (obj.filter && obj.filter.length > 0) {
                            for(var row in obj.state.filterRowRefs) {
                                let filterRowRef = obj.state.filterRowRefs[row];
                                for(var row2 in filterRowRef.current.state.filterRefs) {
                                    let filterRef = filterRowRef.current.state.filterRefs[row2];
                                    if(filterRef.ref.current.filterObj[_rawFileSubtypeId] === subtypeId && filterRef.ref.current.filterObj[_column] === value) {
                                        obj.filter[row][row2][_valueOptions] =  values;
                                        filterRef.ref.current.filterObj[_valueOptions] = values;
                                        filterRef.ref.current.isLoading = false;    
                                    }
                                }                                
                            }
                        } 
                        obj.setState({
                            didMount:false
                        })

                        // obj.setState({
                        //     isAddConditionEnabled:true
                        // })
                        // let menuIsOpen = obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
                        // obj.setState({
                        //     ["values_"+index]: values,
                        //     ["function_"+index]: functionValue
                        // }, function() {
                        //     if(menuIsOpen) {
                        //         obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.selectRef.focus();
                        //     }
                        // });
                    } else {
                        obj.state[subtypeId+"_"+value+"_values"] = values;
                        callback(values);
                    }

                }

            }).catch((error)=>{
                alertAndLogError(error);
        });
    }

    getAllFileTypeColumns(fileTypeObj, identifier, index, condition, conditionArr, rowIndex, callback, attr, rowNum) {
        var obj = this;
        if (condition !== undefined && !fileTypeObj.subSection) {
            return;
        }
        var data = fileTypeObj.subSection ? this.props.dataColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === fileTypeObj.subSection) :
                    fileTypeObj.section ? this.props.dataColumns.filter(e=>e[RAW_ITEMS.TYPE_NAME] === fileTypeObj.section) : [];
        let fields = [];
        let allFields = [];
        data.map(function(item) {
            let description = item[RAW_ITEMS.DESCRIPTION_FIELD] ? " (" + item[RAW_ITEMS.DESCRIPTION_FIELD] + ")" : "";
            let label = (item[RAW_ITEMS.DATA_FIELD] && item[RAW_ITEMS.DATA_FIELD] !== "" 
                ? item[RAW_ITEMS.DATA_FIELD] : item[RAW_ITEMS.FIELD_NAME]) + description;

            var currVal = { 
                // label: item[RAW_ITEMS.FIELD_NAME],
                label: label,
                value: item[RAW_ITEMS.FIELD_NAME],
                [RAW_ITEMS.SUBTYPE_NAME]: item[RAW_ITEMS.SUBTYPE_NAME],
                [RAW_ITEMS.SUBTYPE_ID]: item[RAW_ITEMS.SUBTYPE_ID],
                field_data_type:item[RAW_ITEMS.FIELD_DATA_TYPE]
            };
            
            allFields.push(currVal);            
            if((item[RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === "STRING" || item[RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === "BOOLEAN") 
            && fields.filter(e=>e.value === item[RAW_ITEMS.FIELD_NAME]).length === 0) {
                fields.push(currVal);
            }
        });
        if(fileTypeObj.subSection === "calculated_columns"){
            allFields = obj.props.calcColsData.map((e)=>{
                let label = e.display_column_name;
                let value = e.display_column_name;
                let fieldDataType = "NUMERIC";
                return {label:label,value:value,field_data_type:fieldDataType}
            })
        }
        if(callback) {
            callback(allFields);
        } else if (!identifier) {
            obj.state["files_"+index] = allFields;
            obj.state["file_"+index] = fileTypeObj.subSection;
            obj.state["raw_file_subtype_id"+index] = fileTypeObj.raw_file_subtype_id;
            obj.filter[index][rowIndex]["column_options"] =  allFields;
            if(obj.state.filterRowRefs[index]){
                obj.state.filterRowRefs[index].current.state.filterRefs[rowIndex].ref.current.filterObj[_columnOptions] = allFields;
            }
            obj.forceUpdate();
        } else {
            if (index !== undefined && condition) {
                if (attr) {
                    var arr =  this.state[identifier+"TrueFieldsConcat"] ? this.state [identifier+"TrueFieldsConcat"] : [];
                    arr[index] = arr[index] ? arr[index] : [];
                    arr[index][rowNum] = fields;
                    this.state[identifier+"TrueFieldsConcat"] = arr;
                }else if (obj.state[identifier+"Fields_true"] !== undefined && index !== 0 &&  obj.state[identifier+"Fields_true"][index-1] !== undefined) {
                    obj.state[identifier+"Fields_true"][index] = fields;
                    obj.state["trueCondition_"+identifier] = conditionArr;
                }else {
                    if(index !== 0 && obj.state[identifier+"Fields_true"] === undefined) {
                        obj.state[identifier+"Fields_true"]=[]
                        for(var i=0; i<index; i++){
                            obj.state[identifier+"Fields_true"].push([]);
                        }
                        obj.state[identifier+"Fields_true"][index] = fields;
                    }else if (obj.state[identifier+"Fields_true"] !== undefined &&  obj.state[identifier+"Fields_true"].length >0){
                        obj.state[identifier+"Fields_true"][index] = fields;
                    }else{
                        obj.state[identifier+"Fields_true"] = [fields];
                    }
                    obj.state["trueCondition_"+identifier] = conditionArr;
                }
                obj.forceUpdate();      
            } else if (condition === false) {
                if (attr) {
                    var arr =  this.state[identifier+"FalseFieldsConcat"] ? this.state [identifier+"FalseFieldsConcat"] : [];
                    arr[index] = arr[index] ? arr[index] :[];
                    arr[index][rowNum] = fields;
                    this.state[identifier+"FalseFieldsConcat"] = arr;
                    this.forceUpdate();
                } else {
                    obj.state[identifier+"Fields_false"] = [fields];
                    obj.state["falseCondition_"+identifier] = conditionArr;
                    obj.forceUpdate();     
                }
            } else {
                if (attr) {
                    var arr =  this.state[identifier+"FieldsConcat"] ? this.state [identifier+"FieldsConcat"] : [];
                    arr[rowNum] = fields;

                    this.state[identifier+"FieldsConcat"] = arr;
                    this.forceUpdate();
                }else if(JSON.stringify(fields) !== JSON.stringify(this.state[identifier+"Fields"])) {
                    this.setState({     //do not affect the state used by something other than the identifiers
                        [identifier+"Fields"]: fields,
                    });
                }
            }
        }  
    }

    /**
     * this request validates that the data in the primary key for the chosen anc file
     * is present in the vector data for this period
     */
    getMetricDataValidation(type, fromMount) {
        var obj = this;
        var arr = [];
        var finalData= [];
        if (fromMount) {
            finalData = this.state.mappedMetric.metric_configuration && this.state.mappedMetric.metric_configuration.length > 0
            ? this.state.mappedMetric.metric_configuration[0].metricConfiguration : [];
        } else {
            var rows = obj.metricConfRef ? obj.metricConfRef.state.metricConfigurationRows : [];
            for (var e in rows) {
                if(rows[e].current){
                    arr.push(rows[e].current.state.metricConfigurationObject);
                }        
            }
            var types = [];
            for(var e in arr) {
                if (!types.includes(rows[e][RAW_ITEMS.SUBTYPE_NAME])) {
                    finalData.push(arr[e]);
                    types.push(arr[e][RAW_ITEMS.SUBTYPE_NAME]);
                }
            }
        }
        var _break = false;
       this.metricValidation ="";
       for (var e in finalData) {
           if ((finalData[e]["vector-column"] === "none" || !finalData[e]["vector-column"] || !finalData[e][RAW_ITEMS.FIELD_NAME]) && [UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES,UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(finalData[e][RAW_ITEMS.TYPE_NAME])) {
            _break = true;
           }
       }
        if(finalData.filter(e=> [UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES,UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER].includes(e[RAW_ITEMS.TYPE_NAME])).length && !_break && this.state.mappedMetric.vector !== "") {
            this.setState({
                showLoader:true
            })
            var query = {
                action: "getMetricDataValidation",
                timePeriod: this.state.periodName,
                scenario_id: this.props.scenarioId,
                metricRows: JSON.stringify(finalData),
                metricFilter:this.state.mappedMetric?.filter,
                metricVector: this.state.vectorList.length > 0
                    && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])
                    && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0]
                    && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0].valueUsed
                    ? this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0].valueUsed : "",
                vectorType: this.state.vectorList.length > 0
                && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])
                && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0]
                && this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0].vectorType
                    ? this.state.vectorList.filter(e => e.value === obj.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR])[0].vectorType : ""
            }
    
            this.state.validatedMetricData = false;
            this.state.getMetricDataValidationRequestReceived = false;
   
            let onThenCallback = (data) => {
                if(data.data) {
                    var messages=[];
                    for (var i in data.data) {
                        var count = data.data[i].COUNT + " of " + data.data[i].Total;
                        var percentage = (data.data[i].Total === "0" || data.data[i].COUNT === "0" ? "0%" : formatValString((Number(data.data[i].COUNT) / Number(data.data[i].Total)) * 100 , FormatTypes.PERCENTAGE));
                        var file = "("+ (Number(i) + 1).toString() + ")";
                        var text = MESSAGES.metric_mapping_primary_key_not_in_vector.replace('{y}', count +" ("+percentage+")" ).replace('{x}', file);
                        messages.push(text);
                    }
                    obj.metricValidation = messages;
                }
                obj.setState({
                    showLoader: false,
                    getMetricDataValidationRequestReceived: true
                },function(){
                    obj.forceUpdate();
                });
            }
    
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getMetricDataValidation",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.screenName]:lang.observability.stage.metric_mapping.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.metric_mapping.requests_description.get_metric_data_validation
            }
    
            fetchAPI(fetchOptions);
        }
       
    }

    onChangeMetricAttribute(attr, e) {
        var obj = this;
        if(this.isProfitStackMapping) {
            if(obj.isPSCalculated) {
                this.calcPsl[PS_MAPPING.FIELDS.DESCRIPTION] = e.currentTarget.value;
            } 
            return;
        }
        var mappedMetric = this.state.mappedMetric;
        let metricIndex = this.state.mappedMetric?.index;
        let value = "";
       
        if(attr === METRICS_MAPPING.FIELDS.METRIC) {
            if(checkForSpecialChars(e.currentTarget.value) || checkStartsWithDigit(e.currentTarget.value)) {
                var message =  MESSAGES.invalid_metric_name;
                this.isNameValid = message;
            } else {
                this.isNameValid = "";
            }
            
            // Exclude name validation on the metric your editing
            var metrics = this.props.metricFields.filter(e => e.index !== metricIndex);
           
            for (var nb in metrics ) {
                if(is_aN(Number(nb))) {
                    if (metrics[nb].metric_display_name && metrics[nb].metric_display_name.toLowerCase() === e.currentTarget.value.toLowerCase() && metrics[nb].row_status !== 'deleted' ) {
                        this.isDuplicateName = true;
                        break;
                    } else {
                        this.isDuplicateName = false;
                    }
                } 
            }
        }

        if(attr === METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE) {
            //if the user changed the ancillary file (metric ->> file_type), change 
            //metric ->> display_file_type so they're always consistent
            mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_DISPLAY_SUBTYPE] = e.label;
            mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE] = e.value;
            mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE_ID] = e.raw_file_subtype_id;
            mappedMetric[METRICS_MAPPING.FIELDS.DATA_TYPE_FIELD] = e.type;
            mappedMetric[METRICS_MAPPING.FIELDS.FILTER] = "";
            mappedMetric[METRICS_MAPPING.FIELDS.TRANSLATED_FILTER] = "";
            if(obj.filterModalRef && obj.filterModalRef.filterDialRef) {
                obj.filterModalRef.filterDialRef.state.filterRefs = [];
                obj.filterModalRef.filterDialRef.updateAdvancedData([]);
                obj.filterModalRef.filterDialRef.addNewFilter(true, _logicalOperator, "");
             }  
           
        }
        if([METRICS_MAPPING.FIELDS.VECTOR].includes(attr)) {
            if (e.value === this.props.costCenter.toUpperCase()) {
                mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER] = "false";
                this.setState({
                    toggleDisabled:false
                })
            } else {
                this.setState({
                    toggleDisabled:true
                })
                this.checkForCostCenter();
            }
        }
        if([METRICS_MAPPING.FIELDS.COST_CENTER, METRICS_MAPPING.FIELDS.METRIC, METRICS_MAPPING.FIELDS.DESCRIPTION].includes(attr)) {
            if (attr === METRICS_MAPPING.FIELDS.COST_CENTER) {
                value = e.currentTarget.value === CC_LABELS.ON ? "false": "true";
            }else {
                value = e.currentTarget.value;
            }
        } else if(e !== null){
            value = e.value;
            if([METRICS_MAPPING.FIELDS.VECTOR].includes(attr)) {
                mappedMetric[METRICS_MAPPING.FIELDS.VECTOR_DISPLAY] = e.label
            }
        }

        mappedMetric[attr] = value;
        // this.state.mappedMetric[METRICS_MAPPING.FIELDS.STATUS] = PERIOD_STATUS_ENUM.INVALID;

        if(![METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NEW, METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED].
            includes(mappedMetric[METRICS_MAPPING.FIELDS.ROW_STATUS])) {
            //if row is not NEW and NOT_SAVED, hasn't been just created, set it to edited
            mappedMetric[METRICS_MAPPING.FIELDS.ROW_STATUS] = METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.EDITED;
        }

        this.setState({
            mappedMetric: mappedMetric,
        }, function() {
            if([METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE, METRICS_MAPPING.FIELDS.VECTOR].indexOf(attr) > -1) {
                obj.getMetricDataValidation();
            }
        });
    }

    checkForCostCenter(disableBranch) {
        var obj = this;
        var res = disableBranch ? false : true;
        var metricConfRows = this.metricConfRef ? this.metricConfRef.state.metricConfigurationRows : [];
        if (disableBranch && this.state.fileHasCC === false) {
            res = false;
        } else {
            for(var e in metricConfRows) {
                if( metricConfRows[e].current && metricConfRows[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_ID] !== "") {
                    var columns = obj.props.allTypeColumns && obj.props.allTypeColumns.length > 0 ? obj.props.allTypeColumns.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === metricConfRows[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_ID] && metricConfRows[e].current.state.metricConfigurationObject[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.MASTER_DATA) : [];
                    var costCenterCol = columns.filter(el=>el.name.toUpperCase() === cost_center).length > 0;
                    if (!costCenterCol && columns.length > 0) {
                        res = false;
                        break;
                    }
                }
            }
        }
        
        this.updateConfigFileCC(res);
    }

    updateConfigFileCC(hasCC) {
        let tempMetric = copyObjectValues(this.state.mappedMetric);
        if(!hasCC) {
            tempMetric[METRICS_MAPPING.FIELDS.COST_CENTER] = "false";
        }

        this.setState({
            fileHasCC: hasCC,
            mappedMetric: tempMetric
        });
    }

    parseMetricAmount(filter, fileType, field, callback) {
        if (this.state.chosenMapExceptionValue !== ANCILLARY) {
            return;
        }

        toggleLoader(true, "parseMetricAmount", true);
        var query = {
            action: "parseMetricAmount",
            timePeriod: this.state.periodName,
            scenario_id: this.props.scenarioId,
            fileType: fileType,
            filter: filter && filter !== "" ? "{'filter':"+JSON.stringify(filter)+"}" : "",
            rawFileFieldName: field
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json();
            }).then((data)=>{
                if (data.parsedAmount) {
                    callback(parseFloat(data.parsedAmount[0].amount));
                } else if(data.ERROR) {
                  this.setInfoDialogOpen(true, "There's something wrong with your query.");
                }
                toggleLoader(false, "parseMetricAmount", true);

            }).catch((error)=>{
                toggleLoader(false, "parseMetricAmount", true);
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "parseMetricAmount", true);
            });
    }

    parseMapExceptionQuery(filter, fileType, field, callback) {
        if (this.state.chosenMapExceptionValue !== ANCILLARY) {
            return;
        }

        toggleLoader(true, "parseMapExceptionQuery", true);
        var query = {
            action: "parseMapExceptionQuery",
            timePeriod: this.state.periodName,
            scenario_id: this.props.scenarioId,
            fileType: fileType,
            fields: this.props.allAncillaryColumns ? JSON.stringify(this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === fileType)) : [],
            filter: filter && filter !== "" ? "{'filter':"+JSON.stringify(filter)+"}" : "",
            startDate: this.props.periodDetails && this.props.periodDetails[this.state.periodName] && this.props.periodDetails[this.state.periodName]["start_date"] ? this.props.periodDetails[this.state.periodName]["start_date"] : "",
            endDate: this.props.periodDetails && this.props.periodDetails[this.state.periodName] && this.props.periodDetails[this.state.periodName]["end_date"] ? this.props.periodDetails[this.state.periodName]["end_date"] : "",
            rawFileFieldName: field
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json();
            }).then((data)=>{
                if (data.parsedAmount) {
                    callback(parseFloat(data.parsedAmount[0].amount));
                } else if(data.ERROR) {
                  this.setInfoDialogOpen(true, "There's something wrong with your query.");
                }
                toggleLoader(false, "parseMapExceptionQuery", true);

            }).catch((error)=>{
                toggleLoader(false, "parseMapExceptionQuery", true);
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "parseMapExceptionQuery", true);
            });
    }

    onChangeExceptionDriver(exceptionValue, reset) {
        let hasChanged = false;
        if (this.state.chosenMapExceptionValue.includes(exceptionValue)) {
            hasChanged = true;
        }
        if(this.props.mappedLine[PS_MAPPING.FIELDS.LEADING_COSTKEY] && this.props.mappedLine[PS_MAPPING.FIELDS.LEADING_COSTKEY] !== this.props.mappedLine[PS_MAPPING.FIELDS.COST_KEY] && reset) {
            window._profitIsleOpenModal('combinationsChanges');
        }
        this.setState({
            chosenMapExceptionValue: exceptionValue,
            parsedAmount: null,
            changed: hasChanged,
        });
    }

    onChangeCostTerm(option) {
        this.setState({
            cost_term_id: option.cost_term_id
        })
    }
    setChosenAncillaryFile(option) {
        this.setState({
            ancillaryFile: option
        },function(){
            // this.parseMapExceptionQuery();
            $("#filter_div").removeClass("disabled");
        });
    }

    getObjOfSubtypeAttr(data, attr, value) {
        var tempObj = {};
        if(data) {
            data.map((opt) => {
                if(opt[attr] === value) {
                    tempObj = opt;
                }
            });
        }

        return tempObj;
    }

    showUploadStatusResponse(cell) {
        //pass response to parent
        var rowData = cell.getRow().getData();
        var response = JSON.parse(rowData["response"]);

        this.props.updateUploadStatusResponse(response);
    }
    
    setMappingReport(report) {    
        //update the conditions so they can be used in the file
        if(report === STAGING_SECTIONS.METRICS_MAPPING) {
            this.isMetricMapping = true;
            this.isVectorMapping = false;
            this.isProfitStackMapping = false;
        } else if(report === STAGING_SECTIONS.VECTOR_MAPPING) {
            this.isVectorMapping = true;
            this.isMetricMapping = false;
            this.isProfitStackMapping = false;
        } else if(report === _calcCols) {
            this.isCalculatedCols = true;
        } else  {
            this.isProfitStackMapping = true;
            this.isExclusions = this.props.accountsType === this.props.EXCLUDED;
            if(!this.isExclusions) {    //if isExclusions = true, none of the following can be true
                this.isPSStandard = this.props.pslLine[_costType] === costtype.standard;
                this.isPSCalculated = this.props.pslLine[_costType] === costtype.calculated;
                this.isPSAttribute = this.props.pslLine[_costType] === costtype.attribute;
            }

            this.isVectorMapping = false;
            this.isMetricMapping = false;
        }

    }

    checkAllLines() {
        var data = this.state.GLAccountsData;
        var count = data.filter(el => el.checked === "true").length;
        var length = this.tabulator.getData().length;
        if (count !== length) {
            data.map(function (item) {
                item["checked"] = "true";
            });
        } else {
            data.map(function(item){
                item["checked"] = "false";
            })
        }
        this.state.GLAccountsData = data;
        this.tabulator.replaceData(data);
        this.checkColumn();
    }

    addCheckRowFormatter(cell, params) {
        var obj = this;
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var checkbox = createCheckbox();
        checkbox.name='chosenEntity';
        checkbox.id = "main_checkbox";
        checkbox.classList.add('chosenEntity', 'uk-margin-small-right');
        checkbox.onchange = (e) => {
            obj.checkAllLines();
            obj.calculateDefaultAssignedValuesSum(e);
        };     
        div.appendChild(checkbox);
        return div;
    }

    hideGLAccount() {
        //so that the chart is shown after the Map comp is hidden completely
        this.props.disableHeaderButtons(false); 
        this.props.hideGLAccounts(function(){
            // $('.vector-mapping-parent').css('width', '100%');
        });
        if (this.isVectorMapping) {
            this.props.cancelVector();
        }
    }

    getMappingTableData(period, filter, combFlag) {
        if(this.isProfitStackMapping && !this.isPSCalculated) {
            this.getGLAccounts(period, filter, combFlag);
        }
    }

    getGLAccounts(period, filter, combFlag) {
        var obj = this;
        toggleLoader(true, "getGLAccounts", true);
        var query = {
            action: "getGLAccounts",
            timePeriod: period ? period : this.state.periodName,
            scenario_id: this.props.scenarioId,
            costKey: this.props.glType !== 'ALL' ? '' : this.props.pslLine[_costKey],
            accountsType: this.props.glType !== '' ? this.props.glType : this.state.chosenAccountType.value,
            mappedLines: this.state.mappedLines,
            filter: filter ? filter : this.state.filter ? translateFilter(JSON.parse(this.state.filter).filter, true) : "",
            fieldsCombination: JSON.stringify(this.state.selectedFieldsCombination),
            combFlag: combFlag !== undefined ? combFlag.toString() : "" // if filter currently applied all data must be checked
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, { mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(query) })
            .then((response) => {
                logoutIfUnauthenticated(response, this);
                return response.json();
            }).then((data) => {
                obj.setGLAccountsData(data.data, data.amountsSum, data.exclusionFilter, data.jsonExclusionFilter);
            }).catch((error) => {
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, "getGLAccounts", true);
            });
    }

    getMetricData(period, filter) {
        if(!this.props.validateMetricData(this.state.mappedMetric, METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE)) {
            //if ancillary file is not selected, do not send request
            return;
        }

        if (!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "getMetricData", true);
        }

        var query = {
            action: "getMetricData",
            timePeriod: period ? period : this.state.periodName,
            scenario_id: this.props.scenarioId,
            mappedMetric: JSON.stringify(this.state.mappedMetric),
            filter: filter !== undefined ? filter : ""
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json();
            }).then((data)=>{
                data = data.data;
                var totalEntities = "-";
                var totalAmount = "-"
                //setting total entities and total amount
                if(data && data.length > 0) {
                    totalEntities = data[0][METRIC_MAPPING_COLUMNS.FIELDS.TOTAL_ENTITIES];
                    totalAmount = data[0][METRIC_MAPPING_COLUMNS.FIELDS.TOTAL_AMOUNT];
                }

                this.setState({
                    metricData: data,
                    assignedAccount: totalEntities.toString(),
                    assignedAmount: totalAmount.toString()
                },function(){
                    this.tabulator.setData(data)
                });
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "getMetricData", true);
            });
    }

    getAncillaryFileColumns(fromMount) {
        if(!this.props.validateMetricData(this.state.mappedMetric, METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE)) {
            //if ancillary file is not selected, do not send request
            return;
        }

        if (!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "getAncillaryFileColumns", this.isMetricMapping ? false : true);
        }

        var query = {
            action: "getAncillaryFileColumns",
            scenario_id: this.props.scenarioId,
            mappedMetric: JSON.stringify(this.state.mappedMetric)
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                logoutIfUnauthenticated(response, this);
                return response.json();
            }).then((data)=>{
                if(data && data.data) {
                    this.setState({
                        ancillaryFileColumns: data.data.map(function(item){
                            return {label: item[DEFINE_FIELDS.FIELD_NAME_FIELD], value: item[DEFINE_FIELDS.FIELD_NAME_FIELD], type: item[DEFINE_FIELDS.DATA_TYPE_FIELD], field_data_type: item[DEFINE_FIELDS.DATA_TYPE_FIELD]}
                        })
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "getAncillaryFileColumns", true);
            });
    }

    
    setFilterAttrOptions(rowIndex, attribute, options, subType) {
        var _this = this;
        if(!this.filterModalRef.filterDialRef.state.filterRefs[rowIndex] || !this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.forceUpdate(); //You need to re-render row to remove loader from dropdown in values option
        }
        if (attribute === _fileOptions) {
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = subType;
        }

        if(options && options !== "" && options.length > 0){
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options.filter(e=>e.value!==undefined);
        }
        let menuIsOpen = this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        if(menuIsOpen) {
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(function() {
                _this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
                
            })
        }
    }

    setGLAccountsData(data, amountsSum, exclusionFilter, jsonExclusionFilter) {
        this.tabulator.clearFilter(true);   //clear all table filters when data is updating
        var tempState = {
            exclusionFilter: exclusionFilter,
            jsonExclusionFilter: jsonExclusionFilter
        }
        if(data){
            tempState.GLAccountsData = data;
            tempState.amountsSum = amountsSum;
        }
        this.setState(tempState);

        this.checkColumn();
    }

    translateEngineFilter(json_filter) {
        var obj = this;
        var requestedFilter ={filter:json_filter};
        var dataBody = { 
            action: "translateFilter",
            scenario_id: this.props.scenarioId,
            identifier: requestedFilter
        };
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{
                return response.json();
            }).then((data)=>{
                obj.state.mappedMetric[METRICS_MAPPING.FIELDS.FILTER] = requestedFilter;
                obj.state.mappedMetric[METRICS_MAPPING.FIELDS.TRANSLATED_FILTER] = data.data;
                obj.forceUpdate();
            })
        .catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{
        });
    }

    applyFilter(filter){
        let tempState = {};
        if(this.isMetricMapping) {
            let mappedMetric = this.state.mappedMetric;
            if(filter.length > 0) {
                mappedMetric[METRICS_MAPPING.FIELDS.FILTER] =  "{\"filter\":"+ JSON.stringify(filter) +"}";
            } else{
                mappedMetric[METRICS_MAPPING.FIELDS.FILTER] = "";
                mappedMetric[METRICS_MAPPING.FIELDS.TRANSLATED_FILTER] = "";
                $("#filterFinalValue").val("");
            }
            if(![METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NEW, METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.DELETED].includes(mappedMetric[ROW_STATUS.FIELD])) {
                mappedMetric[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
            }

            tempState.mappedMetric = mappedMetric;
        }

        this.setState(tempState,()=> {
            if(this.isMetricMapping){
                this.getMetricDataValidation()
            }
        });
    }

    discardFilter() {
        var _this = this;
        if(_this.state.mappedMetric && _this.state.mappedMetric[METRICS_MAPPING.FIELDS.FILTER] && _this.state.mappedMetric[METRICS_MAPPING.FIELDS.FILTER].length === 0) {
            _this.filterModalRef.filterDialRef.state.filterRefs=[];
            _this.filterModalRef.filterDialRef.updateAdvancedData([]);
        }
    }

    saveFilter(filter) {
        var obj = this;
        if (obj.isMetricMapping) {
            obj.applyFilter(filter);
            return;
        }
        
        this.setState({
            filter: "{\"filter\":"+ JSON.stringify(filter) +"}",
            showFilter: false,
            combFlag: false
        }, function() {
            obj.getMappingTableData(undefined, translateFilter(filter, true),false);
            if(obj.isProfitStackMapping) {
                obj.props.callTotals(undefined, false, undefined, true);
            }
        });
    }

    saveMapExclusions(excludedFieldsCombination, accountsType) {
        toggleLoader(true, "saveMapExclusions",true);
        var obj = this;
        var myUrl = baseUrl+path;
        var query = {
            action:"saveExcludeFieldsCombination",
            timePeriod: this.state.periodName,
            scenario_id: this.props.scenarioId,
            tablePrefix: this.props.tablePrefix,
            accountsType: accountsType === true ?  ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION: this.state.chosenAccountType.value, // called from pslMapping and ManageExclusions
            excludedFieldsCombination: excludedFieldsCombination
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: myUrl, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            error: function(error) {
                toggleLoader(false, "saveMapExclusions", true);
                obj.getGLAccounts();
            },
            complete: function(xhr, textStatus) {
                // var filter = obj.state.filter !== "" ? translateFilter(JSON.parse(obj.state.filter.replaceAll("'","\"")).filter, true) : ""
                var filter = obj.state.filter !== "" ? translateFilter(JSON.parse(obj.state.filter).filter, true) : ""
                obj.getGLAccounts(undefined, filter);
                obj.props.callTotals(undefined, true, true);
                toggleLoader(false, "saveMapExclusions",true);
                obj.hideGLAccount();
            }
        });
    }

    showPopup() {

        var count =  this.tabulator && this.tabulator.getData().filter(el => el.checked === "true").length;
        if (count && count > 0) {
            window._profitIsleOpenModal("confirmCombinationUncheckModal");
        } else {
            this.showFilterModal();
        }
    }

    showFilterModal() {
        let _this = this;
        this.setState({
            showFilter: true
        }, function(){
            if(_this.isProfitStackMapping) {
                window._profitIsleOpenModal('filterEngineModal');
            } else if (_this.isMetricMapping) {
                _this.filterModalRef.showFilterModal();
            }
        });
    }

    onChangeExcludedAccount(option) {
        var obj = this;
        this.setState({
            chosenAccountType: option,
            filter: ""
        }, function() {
            obj.getGLAccounts();
        });
    }

    returnDisabledStatus() {
        var isProfitStackMapping = this.props.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING;

        // if(isProfitStackMapping && ((this.state.parsedAmount === null || !this.state.parsedAmount && this.state.chosenMapExceptionValue === ANCILLARY)
        //      && this.state.chosenMapExceptionValue !== NONE && this.state.chosenMapExceptionValue !== TRANSACTION)){
        //     return "disabled";
        // } else if (isProfitStackMapping && this.state.chosenMapExceptionValue === TRANSACTION) {
        //     return ""
        // } else if (isProfitStackMapping && !this.state.changed) {
        //     return "disabled";
        // } else if ((this.props.isManageExclusions || this.props.isManageExclusions) && !this.state.changed) {
        //     return "disabled";
        // } else {
            return "";
        // }
        
    }

    removeFilter(e) {
        if (this.ancillaryFilterRef) {
            this.ancillaryFilterRef.removeFilterRow(e.currentTarget.attributes);
        }
        var filter = [];
        // var parsedFilter = this.state.ancillaryFilter !== "" ? JSON.parse(this.state.ancillaryFilter.replaceAll("'","\"")).filter : [];
        var parsedFilter = this.state.ancillaryFilter !== "" ? JSON.parse(this.state.ancillaryFilter).filter : [];
        if (parsedFilter.length > 0) {
            for (var i in parsedFilter) {
                if (parsedFilter[i].field !== e.currentTarget.attributes.field.nodeValue && parsedFilter[i].value !== e.currentTarget.attributes.filter_value.nodeValue) {
                    filter.push(parsedFilter[i]);
                }
            }
        }
        this.setState({
            ancillaryFilter: filter.length > 0 ? "{\"filter\":"+ JSON.stringify(filter) +"}" : "",
        })
    }

    clearAll() {
        this.setState({
            ancillaryFilter: "",
        })
    }

    setTrueFalseFields(value, identifier) {
        var obj = this;
        var trueCondition = JSON.parse(value).conditions[0].result;
        for (var e in trueCondition) {
            for (var elt in trueCondition[e]) {
                var subSection = obj.state.engineFiles ?obj.state.engineFiles.filter(k=>k["raw_file_subtype_id"] === trueCondition[e][elt]["raw_file_subtype_id"]).length > 0 ? obj.state.engineFiles.filter(k=>k["raw_file_subtype_id"] === trueCondition[e][elt]["raw_file_subtype_id"])[0].value : undefined : undefined;
                obj.getAllFileTypeColumns({subSection:subSection}, identifier, e, true,trueCondition,undefined, undefined, parseInt(elt) > 0);
            }
        }

        var falseCondition = JSON.parse(value).result;
        for (var e in falseCondition) {
            for (var elt in falseCondition[e]) {
                var subSection = obj.state.engineFiles ?obj.state.engineFiles.filter(k=>k["raw_file_subtype_id"] === falseCondition[e][elt]["raw_file_subtype_id"]).length > 0 ? obj.state.engineFiles.filter(k=>k["raw_file_subtype_id"] === falseCondition[e][elt]["raw_file_subtype_id"])[0].value : undefined: undefined;
                obj.getAllFileTypeColumns({subSection:subSection}, identifier, e, false,falseCondition, undefined, undefined, parseInt(elt) > 0);
            }
        }
    }

    getVectorList() {
        var vectors = this.props.vectors;
        var vectorList=[];
        var period = this.state.periodName === "" ? this.props.periodName :  this.state.periodName;
        for (var e in vectors) {
            vectorList.push({value: vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME] , label: vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME], 
            status: vectors[e].period_status, valueUsed:vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID] ? vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE]+"__"+vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID]: vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE], vectorType: vectors[e][VECTOR_MAPPING.FIELDS.VECTOR_TYPE]  })
        }
        this.setState({
            vectorList: vectorList
        })
    }

    getVectorIdentifierFiles(section) {
        var obj = this;
        toggleLoader(true, "getVectorIdentifierFiles", true);
        var query = {
            action:"getVectorIdentifierFiles"
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + path,
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function(data) {
                data = JSON.parse(data);
                var engineFiles = [];
                for (var e in data.data) {
                    engineFiles.push({label:data.data[e][RAW_ITEMS.SUBTYPE_DISPLAY_NAME], value:data.data[e][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.SUBTYPE_ID]:data.data[e][RAW_ITEMS.SUBTYPE_ID], [RAW_ITEMS.TYPE_NAME]:data.data[e][RAW_ITEMS.SECTION]})
                }
                obj.setState({
                    masterAndTDFiles: data.data,
                    engineFiles: engineFiles
                },function(){
                    var keyValue = obj.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE];
                    if (keyValue !== undefined){
                        if (keyValue !== "" &&  keyValue.length > 0  && JSON.parse(keyValue).result && JSON.parse(keyValue).result.length > 0) {

                            var nameValue = obj.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE];
                            var numberValue = obj.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE];
                            obj.setTrueFalseFields(keyValue, IDENTIFIERS.KEY);
                            obj.setTrueFalseFields(numberValue, IDENTIFIERS.NUMBER);
                            obj.setTrueFalseFields(nameValue, IDENTIFIERS.NAME);
                        }
                    }
                })
                
                //if re-opening a previously mapped vector, fetch field options
                obj.checkToFetchNewFields(data.data);
            },
            error: function(error) {
                alertAndLogError(error)
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, "getVectorIdentifierFiles", true);
            }
        });
    }

    //this function checks if the file is previously set for name or number, it refetches the field options for the chosen file
    checkToFetchNewFields(data) {

        if(["", undefined].indexOf(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID]) === -1) {
            let fileSubType = this.getObjOfSubtypeAttr(data, RAW_ITEMS.SUBTYPE_ID, Number(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID]));
            this.getAllFileTypeColumns({subSection: fileSubType[RAW_ITEMS.SUBTYPE_NAME]}, IDENTIFIERS.KEY);
        }
        if(["", undefined].indexOf(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID]) === -1) {
            let fileSubType = this.getObjOfSubtypeAttr(data, RAW_ITEMS.SUBTYPE_ID, Number(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID]));
            this.getAllFileTypeColumns({subSection: fileSubType[RAW_ITEMS.SUBTYPE_NAME]}, IDENTIFIERS.NUMBER);
        }
        if(["", undefined].indexOf(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID]) === -1) {
            let fileSubType = this.getObjOfSubtypeAttr(data, RAW_ITEMS.SUBTYPE_ID, Number(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID]));
            this.getAllFileTypeColumns({subSection: fileSubType[RAW_ITEMS.SUBTYPE_NAME]}, IDENTIFIERS.NAME);
        }
    }

    validateAdvancedFilter(ref, identifier) {
        // this.vectorMappingObj[[identifier+"_value"]];
        var json = this.vectorMappingObj[[identifier+"_value"]];
        if (typeof this.vectorMappingObj[[identifier+"_value"]] === "string") {
            json = JSON.parse(this.vectorMappingObj[[identifier+"_value"]]);
            json.conditions[0].filters = this.filter;
            this.vectorMappingObj[[identifier+"_value"]] = JSON.stringify(json);
        }
        if (json.length === 0 || !json.conditions || !json.result ||    json.conditions.length === 0 || json.result.length === 0) {
            if ($("#"+identifier+"trueInput_0").val() === "" || $("#"+identifier+"falseInput").val() === ""){
                this.setInfoDialogOpen(true, MESSAGES.mandatory_vectors);
                return false;
            }
            this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
            return false;
        }
        
        if (typeof this.vectorMappingObj[[identifier+"_value"]] === "object") {
            this.vectorMappingObj[[identifier+"_value"]].conditions[0].filters = this.filter;
        }
        if (json.length === 0 || !json.conditions || !json.result ||    json.conditions.length === 0 || json.result.length === 0) {
            if ($("#"+identifier+"trueInput_0").val() === "" || $("#"+identifier+"falseInput").val() === ""){
                this.setInfoDialogOpen(true, MESSAGES.mandatory_vectors);
                return false;
            }
            this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
            return false;
        }
        var filters = json.conditions[0].filters ?  json.conditions[0].filters : this.filter;
        var trueConditions = json.conditions[0].result ?  json.conditions[0].result : [];
        var falseConditions = json.result ?  json.result : [];
         if (filters.length === 0 || falseConditions.length === 0 || trueConditions.length === 0) {
            this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
            return false;
         } else{
            if (filters[0].length === 0) {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false; 
            }
            if (filters.length > 0) {
                for(e in filters){
                    for(elt in filters[e]){
                        if(!filters[e][elt].hasOwnProperty("column")){
                            filters[e].splice(elt,1);
                        }
                    }
                }
                for (var e in filters) {
                    if (filters[e].length === 0) {
                        this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                        return false;
                    }
                    for (var elt in filters[e]) {
                        this.filter[e][elt][_logicalOperator] = this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj[_logicalOperator];
                        this.filter[e][elt][_paranthesis_after] = this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj[_paranthesis_after];
                        this.filter[e][elt][_paranthesis_before] = this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj[_paranthesis_before];
                        this.filter[e][elt].values = typeof this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"] === 'object' ?
                        this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"] : [{value: this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"], label : this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"]}];
                        this.filter[e][elt].value = typeof this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"] === 'object' ?
                        this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"] : [{value: this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"], label : this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["values"]}];
                        this.filter[e][elt].operator = this.state.filterRowRefs[e].current.state.filterRefs[elt].ref.current.filterObj["function"];

                        if (filters[e][elt]["file"] === "" || filters[e][elt][ENGINE_FILTER.KEYS.COLUMN] === "" ||
                               filters[e][elt][ENGINE_FILTER.KEYS.OPERATOR] === "" || (filters[e][elt][ENGINE_FILTER.KEYS.VALUE] && 
                               filters[e][elt][ENGINE_FILTER.KEYS.VALUE].length === 0 && ![_empty, _nempty].includes(filters[e][elt][ENGINE_FILTER.KEYS.OPERATOR])) ||
                               !filters[e][elt]["file"] || !filters[e][elt][ENGINE_FILTER.KEYS.COLUMN]  ||
                               !filters[e][elt][ENGINE_FILTER.KEYS.OPERATOR]  || !filters[e][elt][ENGINE_FILTER.KEYS.VALUE]) {
                            this.setInfoDialogOpen(true, MESSAGES.empty_vectors); 
                            return false;
                         }
                    }
                }
                if (typeof this.vectorMappingObj[[identifier+"_value"]] === "object") {
                    this.vectorMappingObj[[identifier+"_value"]].conditions[0].filters = this.filter;
                }else{
                    json = JSON.parse(this.vectorMappingObj[[identifier+"_value"]]);
                    json.conditions[0].filters = this.filter;
                    this.vectorMappingObj[[identifier+"_value"]] = JSON.stringify(json);
                } 
            } 
            trueConditions = trueConditions.filter(item =>item.length!==0);
            if (trueConditions[0].length === 0) {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false; 
            }
            if (this.filter.length > trueConditions.length) {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false; 
            }
            
            if (trueConditions.length > 0) {
                for (var e in trueConditions) {
                    if (!this.validateConnectors(ref, identifier, true, e ,trueConditions[e])) {
                        return false; 
                    }   
                    for (var elt in trueConditions[e]) {
                        if (ref.state["manualTrueDisabled_"+e]) {
                            if (trueConditions[e][elt][ENGINE_FILTER.KEYS.SUBTYPE_ID] === "" || trueConditions[e][elt][ENGINE_FILTER.KEYS.VALUE] === "") {
                                this.setInfoDialogOpen(true, MESSAGES.mandatory_vectors); 
                                return false;
                             }
                        }else{
                            if ($("#"+identifier+"trueInput_"+e).val() === ""){
                                this.setInfoDialogOpen(true, MESSAGES.mandatory_vectors);
                                return false;
                            }
                        }
                        
                    }
                }
            }
            if (falseConditions[0].length === 0) {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false; 
            }
            if (falseConditions.length > 0) {
                for (var e in falseConditions) {
                    if (!this.validateConnectors(ref, identifier, false, e ,falseConditions[e])) {
                        return false; 
                    }   
                    for (var elt in falseConditions[e]) {
                        if (ref.state["manualFalseDisabled"]) {
                            if (falseConditions[e][elt][ENGINE_FILTER.KEYS.SUBTYPE_ID] === "" || falseConditions[e][elt][ENGINE_FILTER.KEYS.VALUE] === "") {
                                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                                return false;
                             }
                        }else{
                            if ($("#"+identifier+"falseInput").val() === ""){
                                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                                return false;
                            }
                        }
                        
                    }
                }
            }
         }
         return true;
    }

    removeAddedFilterAttributes(){
        var filters = this.filter;
        if (filters.length > 0) {
            for (var e in filters) {
                for (var elt in filters[e]) {
                        delete filters[e][elt].column_options;
                        delete filters[e][elt].value_options;
                        delete filters[e][elt].function_options;
                        delete filters[e][elt].file_options
                }
            }
        } 
    }

    validateConnectors(ref, identifier, condition, index, outSideJson) {
        var json = [];
        if (typeof this.vectorMappingObj[identifier+"_value"] === "string") {
            if (this.vectorMappingObj[identifier+"_value"] === "") {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false;
            }
            json = JSON.parse(this.vectorMappingObj[identifier+"_value"]);
        } else {
            json = this.vectorMappingObj[identifier+"_value"];
        }
        if (condition !== undefined) {
            json = outSideJson;
        } 
        var colsNbr = condition === undefined ? ref.state.numberOfCols : (condition ? ref.state["numberOfColsTrue_"+index] : ref.state.numberOfColsFalse);
        if (!json || colsNbr > json.length) {
            this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
            return false;
        }
        for (var e in json) {
            if (json[e] === null) {
                json.splice(e,1);
            } else if(condition !== undefined){
                if (json[e].value === '') {
                  this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                    return false;
                }
            }
        }

        var connCount = json.filter(e=>e.type=== "connector");
        if (connCount) {
            connCount = connCount.length;
        }
        var subTypeCount = json.filter(e=>e.type==="raw_file_subtype_id");
        if(subTypeCount) {
            subTypeCount = subTypeCount.length;
        }
        var fieldCount = json.filter(e=>e.type==="field");
        if (fieldCount) {
            fieldCount = fieldCount.length;
        }
        if (!(connCount === 0 && subTypeCount === 0 && fieldCount === 0)) {
            if (connCount !== subTypeCount ||  fieldCount <= connCount) {
                this.setInfoDialogOpen(true, MESSAGES.empty_vectors);
                return false;
            }
        }
  
        return true;
    }

    setVectorMapping() {
        const vectorDisplayName = this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME]?.replace(/ /g,"")?.toLowerCase();
        const rowStatus = this.vectorMappingObj[VECTOR_MAPPING.FIELDS.ROW_STATUS]
        const vectorId = Number(this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_ID]);
        
        if(!vectorDisplayName|| vectorDisplayName === "-") {
            this.setInfoDialogOpen(true, "Please fill vector name.");
            return;
        }

        const isNameDuplicated = this.props.vectors.filter(e => e.display_name?.replace(/ /g,"")?.toLowerCase() === vectorDisplayName && e.delete != "true" );
        
        if((isNameDuplicated.length && rowStatus === ROW_STATUS.VALUES.NEW && this.props.newRow) || ( isNameDuplicated.length && !isNaN(vectorId)  && vectorId !== 0 && vectorId !== Number(isNameDuplicated[0][VECTOR_MAPPING.FIELDS.VECTOR_ID]))){
            this.setInfoDialogOpen(true, "Vector name already exists");
            return;   
        }
        if (!this.state.isAdvanced) {
            if(!this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID] || 
                this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID] === "-" || this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE] === "" 
                || !this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE].length ) {
                this.setInfoDialogOpen(true, lang.empty_vectors);
                return;            
            }
            if (!this.validateConnectors(this.keyRef, IDENTIFIERS.KEY)) {
                return;
            }
            if(!this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID] || 
                this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID] === "-" || this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE] === "" 
                 || !this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE].length ) {
                this.setInfoDialogOpen(true, lang.empty_vectors);
                return;            
            }
            if (!this.validateConnectors(this.numberRef, IDENTIFIERS.NUMBER)) {
                return;
            }
            if(!this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID] || 
                this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID] === "-" || this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE] === ""
                || !this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE].length) {
                this.setInfoDialogOpen(true, lang.empty_vectors);
                return;            
            }
            if (!this.validateConnectors(this.nameRef, IDENTIFIERS.NAME)) {
                return;
            }
        } else {
            if (this.validateAdvancedFilter(this.keyRef, IDENTIFIERS.KEY)) {
                if (this.validateAdvancedFilter(this.numberRef, IDENTIFIERS.NUMBER)) {
                    if (!this.validateAdvancedFilter(this.nameRef, IDENTIFIERS.NAME)) {
                        return;
                    }
                }else{
                    return;
                }
            }else{
                return;
            }
        }
       
        $('.vector-mapping-parent').css('width', '100%');

        if(JSON.stringify(this.vectorMappingObj) !== JSON.stringify(this.props.vectorMappingObj) &&
            this.vectorMappingObj[VECTOR_MAPPING.FIELDS.ROW_STATUS] !== ROW_STATUS.VALUES.NEW) {
            this.vectorMappingObj[VECTOR_MAPPING.FIELDS.ROW_STATUS] = ROW_STATUS.VALUES.EDITED;
        }
        this.removeAddedFilterAttributes();
        this.props.setVectorMapping(this.vectorMappingObj);
    }

    updateVectorAttribute(attr, eventOrValue, isValue, isKey, falseCondition, trueCondition, identifier) {
        if(attr === VECTOR_MAPPING.ATTR.VECTOR_TYPE) {
            this.vectorMappingObj[VECTOR_MAPPING.ATTR.VECTOR_TYPE] = eventOrValue.currentTarget.value;
            this.forceUpdate();
            return;
        }
        if(!isValue) {
            if([VECTOR_MAPPING.ATTR.VECTOR_NAME, VECTOR_MAPPING.ATTR.VECTOR_DESCRIPTION].includes(attr)) {
                var value = $(eventOrValue.currentTarget).val();
                if(attr ===  VECTOR_MAPPING.ATTR.VECTOR_DESCRIPTION) {
                    this.vectorMappingObj[attr] = value;
                } else {
                    this.vectorMappingObj[VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME] = value;    //when updating machine name, also update the display name
                }
                this.forceUpdate();
                return;
            }
        } else if(!this.state.isAdvanced){
            if(attr === VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID){
                this.vectorMappingObj[attr] = eventOrValue;
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE] = "";
            } else if (attr === VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID) {
                this.vectorMappingObj[attr] = eventOrValue;
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] = "";
            } else if (attr === VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID){
                this.vectorMappingObj[attr] = eventOrValue;
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE] = "";
            } else {
                this.vectorMappingObj[attr] = eventOrValue;
            }
        } else {
            if (identifier) { //preventing error if identifier is undefined
                this.vectorMappingObj[identifier.toLowerCase() + "_value"] = { result: falseCondition, conditions: [{ result: trueCondition, filters: this.filter }] }
            }
        }

        this.updateOtherIdentifiers(isKey);
    }

    updateOtherIdentifiers(isKey) {
            var shouldUpdate = false;
            //if number or name is same as key, when key updates, update them as well
            if(this.numberRef.state.isSameAsKey) {
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID] = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID];
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] = copyObjectValues(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]);
                this.state[IDENTIFIERS.NUMBER+"Fields_true"] = this.state[IDENTIFIERS.KEY+"Fields_true"];
                this.state[IDENTIFIERS.NUMBER+"Fields_false"] = this.state[IDENTIFIERS.KEY+"Fields_false"];
                this.state[IDENTIFIERS.NUMBER+"FieldsConcat"] = this.state[IDENTIFIERS.KEY+"FieldsConcat"];
                this.state[IDENTIFIERS.NUMBER+"TrueFieldsConcat"] = this.state[IDENTIFIERS.KEY+"TrueFieldsConcat"];
                this.state[IDENTIFIERS.NUMBER+"FalseFieldsConcat"] = this.state[IDENTIFIERS.KEY+"FalseFieldsConcat"];
                this.state[IDENTIFIERS.NUMBER+"Fields"] = this.state[IDENTIFIERS.KEY+"Fields"];

                shouldUpdate = true;
            }
            if(this.nameRef.state.isSameAsKey) {
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID] = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID];
                this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE] = copyObjectValues(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]);
                this.state[IDENTIFIERS.NAME+"Fields_true"] = this.state[IDENTIFIERS.KEY+"Fields_true"];
                this.state[IDENTIFIERS.NAME+"Fields_false"] = this.state[IDENTIFIERS.KEY+"Fields_false"];
                this.state[IDENTIFIERS.NAME+"FieldsConcat"] = this.state[IDENTIFIERS.KEY+"FieldsConcat"];
                this.state[IDENTIFIERS.NAME+"TrueFieldsConcat"] = this.state[IDENTIFIERS.KEY+"TrueFieldsConcat"];
                this.state[IDENTIFIERS.NAME+"FalseFieldsConcat"] = this.state[IDENTIFIERS.KEY+"FalseFieldsConcat"];
                this.state[IDENTIFIERS.NAME+"Fields"] = this.state[IDENTIFIERS.KEY+"Fields"];
                shouldUpdate = true;
            } else{
                if (this.nameRef.state.isSameAsNumber) {
                    if(this.numberRef.state.isSameAsKey) {
                        this.nameRef.state.isSameAsNumber = false;
                        this.nameRef.state.isSameAsKey = true;
                    }
                    this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID] = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID];
                    this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE] = copyObjectValues(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]);
                    this.state[IDENTIFIERS.NAME+"Fields_true"] = this.state[IDENTIFIERS.NUMBER+"Fields_true"];
                    this.state[IDENTIFIERS.NAME+"Fields_false"] = this.state[IDENTIFIERS.NUMBER+"Fields_false"];
                    this.state[IDENTIFIERS.NAME+"FieldsConcat"] = this.state[IDENTIFIERS.NUMBER+"FieldsConcat"];
                    this.state[IDENTIFIERS.NAME+"TrueFieldsConcat"] = this.state[IDENTIFIERS.NUMBER+"TrueFieldsConcat"];
                    this.state[IDENTIFIERS.NAME+"FalseFieldsConcat"] = this.state[IDENTIFIERS.NUMBER+"FalseFieldsConcat"];
                    this.state[IDENTIFIERS.NAME+"Fields"] = this.state[IDENTIFIERS.NUMBER+"Fields"];
                    shouldUpdate = true;
                }
            }
        if(shouldUpdate) {
            this.forceUpdate();
            //if saved number identifier or name's file, fetch the new file's columns
            this.checkToFetchNewFields(this.state.masterAndTDFiles);
        }
    }

    componentDidUpdate(prevState, prevProps) {
        if(!this.isVectorMapping && this.tabulator) {
            //set tabulator data
            var tableData = this.state.GLAccountsData;
            var tempColumns = copyObjectValues(GL_ACCOUNTS_COLUMNS);
            if(this.isMetricMapping) {
                tableData = this.state.metricData;
                tempColumns = copyObjectValues(METRIC_MAPPING_TABLE_COLUMNS);
            } else if(this.isExclusions && prevState.chosenAccountType.value === ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION) {
                tempColumns.splice(1, 0, ASSIGNED_COMB_PS_NAME_COL);
            }
            this.tabulator.setData(tableData);

            var tableColumns = cleanUpTabulatorColumns(tempColumns, null, this.refreshFilterDivs, this.tabulator, {id: tableId});
            if(!this.isMetricMapping) {
                tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
            }
            if(this.isProfitStackMapping && prevProps.isManageExclusions && 
              (prevState.chosenAccountType.label === ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION || 
                (prevState.chosenAccountType.label === ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION && tableData.length === 0))){
                tableColumns.shift(checkboxCellObject);
            }
            
            //table columns
            var obj = this;
            tableColumns.forEach(col => {
                if(col.title === CHECK_TITLE){
                    col.titleFormatter = this.addCheckRowFormatter;  
                }
                col.formatter = obj.getColumnFormatter(col.title, col.formatter);
            });
            this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table

            if(this.isProfitStackMapping) {
                this.calculateDefaultAssignedValuesSum();
            }
        }

        if(this.props.isParsingAmount !== prevProps.isParsingAmount && this.isPSCalculated) {
            //update amount in amount box after parseAmount request was received
            this.updateCalcPslAmount(this.calcPsl[_mapFormula], this.props.mappedLines);
        }
        if(prevProps.rowDataCC){
            if(prevProps.rowDataCC !== this.props.rowDataCC){
                this.setState({
                    hasRowCC:this.props.rowDataCC
                })
            }
        }
    }

    componentWillUnmount() {
      this.props.disableHeaderButtons(false); 
    }

    componentDidMount() {
        var obj = this;
        obj.props.disableHeaderButtons(true);
        var costKey = this.props.pslLine && this.props.pslLine.costKey ? this.props.pslLine.costKey  : "";
        var parentCostKey = this.props.pslLine && this.props.pslLine.parentCostKey ? this.props.pslLine.parentCostKey  : "";
        if(this.isVectorMapping) {
            this.getVectorIdentifierFiles();
            this.vectorMappingObj = copyObjectValues(this.props.vectorMappingObj);
            this.vectorMappingObj[VECTOR_MAPPING.ATTR.VECTOR_TYPE] = this.vectorMappingObj[VECTOR_MAPPING.ATTR.VECTOR_TYPE] || vector_type.noAlteration;

            let keyValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE];
            var isAdvanced = false;
            var didMount = false;
            var filter = [];
            if (keyValue && keyValue !== "" && JSON.parse(keyValue).result && JSON.parse(keyValue).result.length > 0) {
                if (JSON.parse(keyValue).result.length > 0){
                    isAdvanced = true;
                    didMount = true;
                    filter = JSON.parse(keyValue).conditions[0].filters;
                    this.filter = filter;
                    for (var e in this.filter) {
                        for (var elt in this.filter[e]) {
                            this.filter[e][elt].function = this.filter[e][elt].operator;
                            this.filter[e][elt].values = this.filter[e][elt].value;
                            // this.filter[e][elt].file_type = this.filter[e][elt].file;
                            // this.filter[e][elt].file = this.filter[e][elt].raw_file_subtype_id;
                            this.filter[e][elt].field_data_type = this.filter[e][elt].field_data_type;
                            var functionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, filter[e][elt].field_data_type, "value_type");
                            this.filter[e][elt].function_options = functionOptions;
                            this.getAllFileTypeColumns({section:"", subSection:this.filter[e][elt].fileType, raw_file_subtype_id: this.filter[e][elt].raw_file_subtype_id}, undefined, e, undefined, undefined, elt);
                            if (!([_eq, _neq] && this.filter[e][elt].field_data_type === "NUMERIC")) {
                                this.getColumnValues(this.filter[e][elt].raw_file_subtype_id, this.filter[e][elt].field_data_type, this.filter[e][elt].column, e, this.filter[e][elt].operator, elt);
                            }
                        }
                    }
                   
                } 
            }
            this.setState({
                isAdvanced : isAdvanced,
                conditionsNumber: filter.length,
                didMount: didMount
            })
        } else if(this.isCalculatedCols) {
            this.getVectorIdentifierFiles();
        } else if(!this.isPSCalculated) {
            this.getAncillaryFiles(true);
            this.getVectorList();
            var option = {};
            var tempState = {
                periodName: this.props.periodName,
                mappedLines: this.props.mappedLines,
                mappedMetric: copyObjectValues(this.props.mappedMetric)
            }

            if (this.isProfitStackMapping) {
                tempState.isVar = this.checkIfVarOrMat(this.props.pssFields, costKey);
                this.getRawFileFieldNames({ section: UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER });

                tempState.filter = this.props.pslLine.filter !== undefined ? this.props.pslLine.filter : "";
                tempState.ancillaryFilter = this.props.pslLine.ancillaryFilter !== undefined ? this.props.pslLine.ancillaryFilter : "";
                tempState.mapExceptionQuery = this.props.pslLine.stagingQuery !== undefined ? this.props.pslLine.stagingQuery : this.state.defaultStagingQuery;
                tempState.ancillaryFile = this.props.pslLine.fileName;
                if (this.props.pslLine.mappingException !== undefined && this.props.pslLine.mappingException !== "") {
                    option = {value: this.props.pslLine.mappingException, label: this.props.pslLine.mappingException};
                }
            } else {
                if(this.props.mappedMetric) {
                    $("#metric_description").val(this.props.mappedMetric[METRICS_MAPPING.FIELDS.DESCRIPTION]);
                    
                }
                tempState.mappedMetric_original = copyObjectValues(this.props.mappedMetric);
                tempState.filter = this.props.filter;
            }

            this.setState(tempState ,function(){
                if(obj.isProfitStackMapping && obj.props.glType === 'ALL') {
                    obj.getSelectedFieldsCombination(obj.getMappingTableData);
                } else if(obj.isMetricMapping){
                    // obj.getMappingTableData();
                    obj.getAncillaryFileColumns(true);
                } else {
                    obj.getMappingTableData();
                }
                $("#map_exception_query").val(obj.state.mapExceptionQuery);
            })
                
            var options = {
                layout: "fitColumns",      //fit columns to width of table
                responsiveLayout: true,  //hide columns that dont fit on the table
                tooltips: true,            //show tool tips on cells
                addRowPos: "top",          //when adding a new row, add it to the top of the table
                history: true,             //allow undo and redo actions on the table
                pagination: false, //"local",       //paginate the data
                movableColumns: false,     //allow column order to be changed
                selectable: false,
                movableRows: false,
                resizableColumns: true,
                autoResize: true,
                resizableRows: false,       //allow row order to be changed
                dataTreeStartExpanded: false,
                dataTreeBranchElement: false, //hide branch element
                virtualDomBuffer: 800,
                placeholder: MESSAGES.no_data_available,
                height: "100%",
                width: "100%",
                tooltips:function(column){
                    return column._cell.value;
                }, renderComplete: function() {
                    var  h = $("#"+tableId).height();
                    if(h > 335) {
                        h = 335;
                    } else if( h < 235){
                        h = 235;
                    }
                    $("#"+tableId).height(h);
                    this.redraw();
                },       
            };

            $(document).on("click", "#GLAccountsModal .mm-popup__btn--ok", function (e) {
                if (this.isProfitStackMapping) {
                    obj.showFilterModal();
                }
            });

            if(!this.isMetricMapping) {
                this.tabulator = new Tabulator(this.refs.mainTable, options);
            }
            if (this.isProfitStackMapping && this.exclDriverRef && this.props.pslLine.mappingException !== undefined && this.props.pslLine.mappingException !== "") {
                this.exclDriverRef.onChangeException(option, false);   //call on change in the component itself and not in this file
            }
        } else if(this.isPSCalculated) {
            this.updateCalcPslAmount(this.calcPsl[_mapFormula]);
        }
        /**
         * This block of commented code is for PI-6264, please keep commented
         */
        // this.combinationsTabulator = new Tabulator("combinationGLLinesTable", options);
    }
    
    shouldComponentUpdate(nextProps) {
        var shouldUpdate = true;
        return shouldUpdate;
    }

    setAdvancedFilter(isAdvanced){
        this.vectorMappingObj["key_value"]= [];
        this.vectorMappingObj["number_value"] = [];
        this.vectorMappingObj["name_value"] = [];
        this.setState({
            isAdvanced: isAdvanced,
            conditionsNumber: isAdvanced ? this.state.conditionsNumber === 0 ? this.state.conditionsNumber+1 : this.state.conditionsNumber : 0
        },function(){
            for(var i =0; i <this.state.conditionsNumber; i++) {
                if (!this.filter[i]) {
                    this.filter.push([]);
                }
            }
        });
    }

    addCondition() {
        if(this.state.isAdvanced) {
            this.setState({
                useOutsideFilter: false,
                conditionsNumber: this.state.conditionsNumber+1
            },function(){
                for(var i=0; i <this.state.conditionsNumber; i++) {
                    if (!this.filter[i]) {
                        this.filter.push([]);
                    }
                }
            });
        }
    }

    unmount(){
        this.setState({
            didMount: false
        })
    }

    spliceTrueConditions(idValue, key, obj, identifier){
        if (typeof idValue === "string" && JSON.parse(idValue).result){
            var value = JSON.parse(idValue);
            var json = JSON.parse(idValue).conditions[0].result ;
            json.splice(key,1);
            obj.state["trueCondition_"+identifier] = this.state["trueCondition_"+identifier] ? obj.state["trueCondition_"+identifier].splice(key,1) : json 
            value.conditions[0].result = json;
            idValue = JSON.stringify(value);
            obj.vectorMappingObj[identifier+"_value"] = idValue;
        } else if(idValue.result){
            var value = idValue;
            var json = idValue.conditions[0].result ;
            json.splice(key,1);
            obj.state["trueCondition_"+identifier] = json; // obj.state["trueCondition_"+identifier] ? obj.state["trueCondition_"+identifier].splice(key,1) : json 
            value.conditions[0].result = json;
            idValue = value;
            obj.vectorMappingObj[identifier+"_value"] = idValue;
        }
        var arr = copyObjectValues(this.state[identifier+"Fields_true"]);
        var arr1 = copyObjectValues( this.state[identifier+"TrueFieldsConcat"]);

        arr.splice(key,1);
        arr1.splice(key,1);

        this.state[identifier+"TrueFieldsConcat"] = arr1;
        // this.updateFields(key, identifier, true);
        this.state[identifier+"Fields_true"] = arr;

    }

    deleteConditionRow(e){
        var rowNumber = $(e.currentTarget)[0].id;
        var obj = this;
        var counter = 0;
        let keyValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE];
        let numberValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE];
        let nameValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE];
        var trueCond = tryParse(keyValue);
        if (rowNumber === 1 && trueCond.conditions && trueCond.conditions.length > 0 && trueCond.conditions[0].result.length === 1) {
            return;
        }
        this.state.filterRowRefs.forEach(function(item, key) {
            let tempRowNumber = Number(key);
            if (tempRowNumber === obj.state.filterRowRefs.length -1 && tempRowNumber === 0) {
                return;
            }
            if (obj.filter[key] && obj.filter[key].length > 0) {
                for (var i in obj.filter[key]) {
                    obj.filter[key][i]["values"] = item.current!== null &&  item.current.state &&   item.current.state.filterRefs[i] && 
                    item.current.state.filterRefs[i].ref &&  item.current.state.filterRefs[i].ref.current !== null &&  item.current.state.filterRefs[i].ref.current
                    &&  item.current.state.filterRefs[i].ref.current.filterObj ? item.current.state.filterRefs[i].ref.current.filterObj.values : obj.filter[key]["values"];
                }
            } 
            if(tempRowNumber === Number(rowNumber)) {
                obj.filter.splice(key, 1);
                obj.spliceTrueConditions(keyValue, key, obj, IDENTIFIERS.KEY);
                obj.spliceTrueConditions(numberValue, key, obj, IDENTIFIERS.NUMBER);
                obj.spliceTrueConditions(nameValue, key, obj, IDENTIFIERS.NAME);
                obj.state.conditionsNumber--;
            }
        });

        this.state.filterRowRefs.forEach(function(item, key) {
            if(obj.filter && obj.filter[key] && obj.filter[key].length !== 0){
                counter++;
            }
        });
        this.state.filterRowRefs.splice(Number(rowNumber),1);
        if(counter === this.state.filterRowRefs.length){
            obj.setIndexFilter(true)
        } else {
            obj.forceUpdate();
        }
         
    }

     
    removeFilterRow(rowIndex, conditionIndex){
        this.filter[conditionIndex].splice(rowIndex, 1);
        if (this.filter[conditionIndex].length ===0) {
            this.filter.splice(conditionIndex, 1);
        }
        this.forceUpdate();
    }

    setIndexFilter(value) {
        
        this.setState({
            useOutsideFilter: value
        })
    }
    
    updateFiles(refs, files, callback) {
        var files = this.props.allTypeColumns;
        var output = [];
        for(var i in files) {
            if(output.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === files[i][RAW_ITEMS.SUBTYPE_NAME]).length === 0){
                output.push({value:files[i][RAW_ITEMS.SUBTYPE_NAME], label:files[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME], [RAW_ITEMS.SUBTYPE_ID]:files[i][RAW_ITEMS.SUBTYPE_ID],
                     [RAW_ITEMS.SUBTYPE_NAME]: files[i][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.TYPE_NAME]: files[i][RAW_ITEMS.TYPE_NAME]});
            }
        }
        var chosenFiles = [];
        for (var e in refs) {
            var obj = refs[e] && refs[e].current && refs[e].current !== null && refs[e].current.state.metricConfigurationObject ? refs[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] : "";
            var temp = output.filter(row=> row[RAW_ITEMS.SUBTYPE_NAME] === obj && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.MASTER_DATA);
            if (temp.length !== 0) {
                if (chosenFiles.length === 0 ) {
                    chosenFiles = chosenFiles.concat(temp);
                } else if (chosenFiles.filter(row=> row[RAW_ITEMS.SUBTYPE_NAME] === obj && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.MASTER_DATA).length === 0)
                    chosenFiles = chosenFiles.concat(temp);
            }
        }
                
        chosenFiles = chosenFiles.concat(output.filter(row=>row[RAW_ITEMS.TYPE_NAME]=== UPLOAD_SECTIONS.FIELDS.MASTER_DATA || row[RAW_ITEMS.TYPE_NAME]=== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA))
        this.extractAncillaryFiles(refs, output);
        if(chosenFiles.filter(row=>row.value === _calcCols).length === 0) {
            chosenFiles.push({value: _calcCols, label:MESSAGES.calculated_columns, [RAW_ITEMS.SUBTYPE_NAME]:_calcCols, [RAW_ITEMS.SUBTYPE_ID]:_calcCols, [RAW_ITEMS.TYPE_NAME]:_calcCols})
        }
        chosenFiles.push({value:"vectors", label:"Vectors", [RAW_ITEMS.SUBTYPE_NAME]:"vectors", [RAW_ITEMS.SUBTYPE_ID]:"vectors", [RAW_ITEMS.TYPE_NAME]:"vectors"});

        this.setState({
            filterFileTypes: chosenFiles,
            metricConfigurationRows: refs
        },function(){
            this.props.updateFilterConditionFiles(chosenFiles);
            if (callback) {
                callback();
            }
        })
    }

    extractAncillaryFiles(refs) {
        // var ancillaryColumns = this.props.allTypeColumns;
        // var show = true;
        // for (var e in refs) {
        //     if (refs[e].current.state.metricConfigurationObject[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES) {
        //         var columns = ancillaryColumns.filter(el=>el[RAW_ITEMS.SUBTYPE_ID] === refs[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME]);
        //         var costCenterCol = columns.filter(el=>el.name === cost_center).length > 0;
        //         var show = costCenterCol.toString() === 'true';
        //         if (!show) {
        //             break;
        //         }
        //     }
        // }
        // if (!show) {
        //     $("#toggle_button").prop('checked',show);
        //     $(".toggle-button").add('disabled');
        // } else {
        //     $(".toggle-button").removeClass('disabled');

        // }
    }

    updateFields(index, identifier, condition) {
        if (condition !== undefined) {
            if (condition) {
                var fields = this.state[identifier+"TrueFieldsConcat"];
            }else{
                var fields = this.state[identifier+"FalseFieldsConcat"];

            }
        } else {
            var fields = this.state[identifier+"FieldsConcat"];
        }
        if (fields) {
            for (var i=Number(index)+1; i<fields.length; i+=3) {
                if (fields[i] && fields[i+3]) {
                    fields[i] = fields[i+3]
                }
            }
        }
        
        this.state[identifier+"FieldsConcat"] = fields;
        this.updateOtherIdentifiers();
        this.forceUpdate();
    }

    showAdvancedFilter(e) {
        var _this = this;
        let callback = ()=>{};
        var filter = "";
        let rowNumber = this.props.mappedMetric[_rowPosition];
        filter = this.state.mappedMetric[METRICS_MAPPING.FIELDS.FILTER];
        filter = filter !== "" && typeof (filter) === "string" ? tryParse(filter).filter : filter ? filter.filter : [];
        var fieldsConcatinated = _this.state.ancillaryFileColumns;
        // var fileOptions = _this.state.ancillaryFiles;
        var allTypeColumns = this.props.allTypeColumns;
        var chosenFiles=[];
        var fileOptions = _this.metricConfRef.filterFiles(RAW_ITEMS.SUBTYPE_NAME, allTypeColumns);
        var refs = this.metricConfRef.state.metricConfigurationRows;
        for (var e in refs) {
            var obj = refs[e] && refs[e].current && refs[e].current !== null && refs[e].current.state.metricConfigurationObject ? refs[e].current.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] : "";
            var temp = fileOptions.filter(row=> row[RAW_ITEMS.SUBTYPE_NAME] === obj && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.MASTER_DATA);
            if (temp.length !== 0) {
                if (chosenFiles.length === 0 ) {
                    chosenFiles = chosenFiles.concat(temp);
                } else if (chosenFiles.filter(row=> row[RAW_ITEMS.SUBTYPE_NAME] === obj && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA && row[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.MASTER_DATA).length === 0)
                    chosenFiles = chosenFiles.concat(temp);
            }
        }
                
        chosenFiles = chosenFiles.concat(fileOptions.filter(row=>row[RAW_ITEMS.TYPE_NAME]=== UPLOAD_SECTIONS.FIELDS.MASTER_DATA || row[RAW_ITEMS.TYPE_NAME]=== UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA))
        chosenFiles.push({value:"vectors", label:"Vectors", [RAW_ITEMS.SUBTYPE_NAME]:"vectors", [RAW_ITEMS.SUBTYPE_ID]:"vectors", [RAW_ITEMS.TYPE_NAME]:"vectors"});
        if(chosenFiles.filter(row=>row.value === _calcCols).length === 0) {
            chosenFiles.push({value:_calcCols, label:MESSAGES.calculated_columns, [RAW_ITEMS.SUBTYPE_NAME]:_calcCols, [RAW_ITEMS.SUBTYPE_ID]:_calcCols, [RAW_ITEMS.TYPE_NAME]:_calcCols})
        }

        if (filter.length === 0) {
            filter = [{[_fileOptions]: fileOptions,
                        [_columnOptions]: fieldsConcatinated.length > 0 ? fieldsConcatinated.filter(el=> el && el.label && el.label !== 'CountLines')  : "",
                    }];
        } else {
            for(var e in filter) {
                var options = [];//_this.props.allTypeColumns;
                var cols = _this.props.allTypeColumns.filter(row=>row[RAW_ITEMS.SUBTYPE_ID] === filter[e][RAW_ITEMS.SUBTYPE_ID]);
                if (filter[e][_fileType] === _calcCols) {
                    cols = _this.props.calculatedColumns;
                    for (var i in cols) {
                        options.push({label:cols[i].label , value:cols[i].value , [RAW_ITEMS.SUBTYPE_NAME]:cols[i][RAW_ITEMS.SUBTYPE_NAME],
                            [RAW_ITEMS.SUBTYPE_ID]: cols[i][RAW_ITEMS.SUBTYPE_ID], field_data_type: cols[i][RAW_ITEMS.FIELD_DATA_TYPE] })
                    }    
                } else if (filter[e][_fileType] === "vectors"){
                    options = _this.props.vectorList;
                } else {
                    for (var i in cols) {
                        options.push({label:cols[i][RAW_ITEMS.FIELD_NAME] , value:cols[i][RAW_ITEMS.FIELD_NAME] , [RAW_ITEMS.SUBTYPE_NAME]:cols[i][RAW_ITEMS.SUBTYPE_NAME],
                                                [RAW_ITEMS.SUBTYPE_ID]: cols[i][RAW_ITEMS.SUBTYPE_ID], field_data_type: cols[i][RAW_ITEMS.FIELD_DATA_TYPE] })
                    }
                }
                filter[e][_columnOptions] = options;
                filter[e][_functionOptions] = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, filter[e][_fieldDataType].toLowerCase(), "value_type");
                callback = function(values) {
                    filter[e][_valueOptions] = values;
                    _this.setFilterAttrOptions(e, _valueOptions, values);
                }
                _this.getColumnValues(filter[e].raw_file_subtype_id, filter[e][_fieldDataType], filter[e][_column],
                    rowNumber, filter[e][_function], Number(e), "",callback);
            }
        }
        if(fileOptions.filter(e=>e[RAW_ITEMS.RAW_FILE_SUBTYPE_ID] === "vectors").length ===0) {
            fileOptions.push({value:"vectors", label:"Vectors", [RAW_ITEMS.SUBTYPE_NAME]:"vectors", [RAW_ITEMS.SUBTYPE_ID]:"vectors", [RAW_ITEMS.TYPE_NAME]:"vectors"});
        }
        this.setState({
            showFilter: true,
            advancedMetricFilter: filter,
            engineModalFiles: chosenFiles
        }, function() {
            _this.filterModalRef.openFilterModal(filter);
        })
    }

    renderVectorMapping() {
        var obj = this;
        let keySubtypeId = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID];
        let keyValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE];
        let numberSubtypeId = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID];
        let numberValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE];
        let nameSubtypeId = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID];
        let nameValue = this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE];

        var keyFieldsValue = {
            [VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID]: keySubtypeId ? Number(keySubtypeId) : "",
            [VECTOR_MAPPING.ATTR.KEY_VALUE]: keyValue ? typeof keyValue === "string" ? JSON.parse(keyValue) : keyValue : [],
        }
        var numberFieldsValue = {
            [VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID]: numberSubtypeId ? Number(numberSubtypeId) : "",
            [VECTOR_MAPPING.ATTR.NUMBER_VALUE]:  numberValue ? typeof numberValue === "string" ? JSON.parse(numberValue) : numberValue : [],
        }
        var nameFieldsValue = {
            [VECTOR_MAPPING.ATTR.NAME_SUBTYPE_ID]: nameSubtypeId ? Number(nameSubtypeId) : "",
            [VECTOR_MAPPING.ATTR.NAME_VALUE]:  nameValue ? typeof nameValue === "string" ? JSON.parse(nameValue) : nameValue : [],
        }
        
        if (this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE] && typeof this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE] === "string") {
            var trueConditionKey = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.KEY]
            var falseConditionKey = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.KEY]
        } else if(this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]) {
            var trueConditionKey =this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.KEY]
            var falseConditionKey =this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.KEY_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.KEY]
        }

        if (this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] && typeof this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] === "string") {
            var trueConditionNumber = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.NUMBER]
            var falseConditionNumber = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.NUMBER]
        } else if (this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]){
            var trueConditionNumber =this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.NUMBER]
            var falseConditionNumber =this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.NUMBER]
        }

        if (this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE] && typeof this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE] === "string") {
            var trueConditionName = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.NAME]
            var falseConditionName = JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).result ? JSON.parse(this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.NAME]
        } else if(this.state.isAdvanced && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]) {
            var trueConditionName =this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.NAME]
            var falseConditionName =this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NAME_VALUE]).result : this.state["falsecondition"+IDENTIFIERS.NAME]
        }

        var filterContainers = [];
        for(var k=this.state.conditionsNumber; k>0; k--) {
            var i = k - 1;
            // this is to show the condition in reverse order
            obj.state.filterRowRefs[i] = obj.state.filterRowRefs[i] || React.createRef();
            filterContainers.push(<div className="vector-engine-filter" key={"filter-" + i} id={"engineFilter_"+i}>
                                    <div className="uk-display-flex align-items-center justify-content-between uk-margin-small-bottom">
                                        <span className="fs-14 uk-text-bold">{"Condition "+ (i+1).toString()}</span>
                                        {this.state.conditionsNumber > 1 ?
                                            <div className="uk-button-icon transparent-bg">
                                                <i className="fa-lg fal fa-trash-alt" id={i} onClick={(e)=>{this.deleteConditionRow(e)}}></i>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    <div className="vectors-filter-container">
                                        <EngineFilterDialog
                                            stagingReport={STAGING_SECTIONS.VECTOR_MAPPING}
                                            showFileDropdown={true}
                                            ref={obj.state.filterRowRefs[i]}
                                            fileTypeOptions={obj.state.engineFiles}
                                            onDropDownChange={obj.changeFilterRow}
                                            rowIndex={i}
                                            useOutsideFilter={this.state.didMount || this.state.useOutsideFilter}
                                            getAllFileTypeColumns={obj.getAllFileTypeColumns}
                                            getColumnValues = {obj.getColumnValues}
                                            scenario_id={this.props.scenario}
                                            outsideFilter={this.filter[i]}
                                            compNumber={i}
                                            getValues={this.getColumnValues}
                                            removeRow={this.removeFilterRow}
                                            setIndexFilter={this.setIndexFilter}
                                            vectorOptions = {obj.props.vectorOptions}
                                            hiddenVectors={obj.props.hiddenVectors}
                                    />
                                </div>
                                </div>);
        }

        var isHistorical = this.vectorMappingObj[VECTOR_MAPPING.ATTR.VECTOR_TYPE] === vector_type.historical;
        return (
            <div className="vector_mapping_container">
                {this.renderModal()}
                <div className="mapping-header vector-mapping">
                    <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">Map a Vector</span>
                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
                </div>
                <div className="vector_mapping_body">
                    <div className="uk-margin-medium-top vector_mapping_body_container" style={{height: "100%", overflow: "scroll", overflowX: "hidden"}}>
                    <div className="uk-flex uk-margin-xmedium-bottom align-items-center">
                        <div className="uk-flex align-items-center uk-margin-default-left uk-margin-remove-bottom fs-14 uk-width-2-3">
                            <div className="width-225">{MESSAGES.VECTOR_MAPPING.VECTOR_NAME_QUEST}</div>
                            <Input id="vector-name" className="form-control uk-margin-xsmall-left  width-350" onBlur={(e) => this.updateVectorAttribute(VECTOR_MAPPING.ATTR.VECTOR_NAME, e)}
                                readOnly={this.vectorMappingObj[VECTOR_MAPPING.ATTR.IS_DEFAULT] === "true" ? true : false}
                                defaultValue={this.vectorMappingObj[VECTOR_STAGING_ATTRIBUTES.NAME]} autoComplete="off" />

                        </div>
                        <i className="fal fa-info-circle uk-margin-xsmall-left fs-12" uk-tooltip={INFO_MESSAGE} />
                        {this.isNameValid !== "" ?
                            <p id="calc-col-warn" className="red italic uk-margin-top uk-margin-large-right">{this.isNameValid}</p>
                            : ""}
                        <div className="uk-flex">
                            <label className="uk-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                                <RadioButton className="radioButton uk-margin-remove" onChange={() => { this.setAdvancedFilter(false) }} value={"Basic"} checked={!this.state.isAdvanced} label={"Basic"}/>
                                {/* <span className="uk-margin-small-left">{"Basic"}</span> */}
                            </label>
                            <label className="uk-flex align-items-center uk-margin-medium-left uk-margin-default-right uk-text-xmedium uk-cursor-pointer">
                                <RadioButton id="advanced" className="radioButton uk-margin-remove" onChange={(ev) => { this.setAdvancedFilter(true) }} checked={this.state.isAdvanced} value={"Advanced"}
                                    label={"Advanced"} />
                                {/* <span className="uk-margin-small-left">{"Advanced"}</span> */}
                            </label>
                        </div>
                    </div>
                    <div className="uk-flex uk-margin-xmedium-bottom uk-margin-medium-top">
                        <div className="uk-flex align-items-center uk-margin-default-left uk-margin-remove-bottom fs-14 uk-width-2-3">
                            <div className="width-400">{MESSAGES.VECTOR_MAPPING.VECTOR_NAME_DESCRIPTION}</div>
                            <textarea id="vector_description" className=" uk-textarea input-default" placeholder={MESSAGES.vector_mapping_description_placeholder} rows={3}
                                onChange={(e) => this.updateVectorAttribute(VECTOR_MAPPING.ATTR.VECTOR_DESCRIPTION, e)} value={this.vectorMappingObj[VECTOR_MAPPING.ATTR.VECTOR_DESCRIPTION]}>
                            </textarea>
                        </div>
                    </div>
                    {
                       
                        <div>
                            <div className="uk-display-flex align-items-center uk-margin-default-left uk-margin-remove-bottom fs-14 uk-margin-xmedium-bottom">
                                <div className="uk-margin-right ">{MESSAGES.VECTOR_MAPPING.fetch_missing_entity}</div>
                                <div className="uk-display-flex">
                                    <label className="uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                                        <RadioButton name="vectorType" className="radioButton uk-margin-remove" onClick={(e) => this.updateVectorAttribute(VECTOR_MAPPING.ATTR.VECTOR_TYPE, e)} value={vector_type.historical} checked={isHistorical} label={"Yes"}/>
                                        {/* <span className="uk-margin-small-left">Yes</span> */}
                                    </label>
                                    <label className="uk-display-flex align-items-center uk-margin-medium-left uk-margin-default-right uk-text-xmedium uk-cursor-pointer">
                                        <RadioButton name="vectorType" className="radioButton uk-margin-remove" onClick={(e) => this.updateVectorAttribute(VECTOR_MAPPING.ATTR.VECTOR_TYPE, e)} value={vector_type.noAlteration} checked={!isHistorical} label={"No"}/>
                                        {/* <span className="uk-margin-small-left">No</span> */}
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                    }
                   
                    {this.state.isAdvanced ? 
                        <Button 
                            label={"Add condition"}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-margin-default-bottom"}
                            disabled={!this.isAddConditionEnabled}
                            leftIcon={<i className="uk-margin-small-right fa-lg far fa-plus-circle" />}
                            onBtnClick={()=>{this.addCondition()}}
                        />
                    :""}
                    {this.state.isAdvanced ? 
                        <div>
                            {filterContainers}
                        </div>
                    :""}    
                    <div>
                        <VectorIdentifier key="VectorIdentifier_1" ref={el=>this.keyRef = el} identifierName={IDENTIFIERS.DISPLAY_NAME.KEY} isKey={true} isNumber={false} isName={false} identifier={IDENTIFIERS.KEY} fieldQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_KEY_FIELD} 
                            updateVectorAttribute={this.updateVectorAttribute} fieldsValue={keyFieldsValue} fileOptions={this.state.masterAndTDFiles}
                            getAllFileTypeColumns={this.getAllFileTypeColumns} fields={this.state[IDENTIFIERS.KEY+"Fields"]} oldFields = {copyObjectValues(this.state[IDENTIFIERS.KEY+"Fields"])} connectors={this.props.connectors}
                            is_default={this.vectorMappingObj[VECTOR_MAPPING.ATTR.IS_DEFAULT]} status={this.props.newRow} getObjOfSubtypeAttr={this.getObjOfSubtypeAttr} fileQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_KEY_FILE}
                            updateOtherIdentifiers={this.updateOtherIdentifiers} isAdvanced={this.state.isAdvanced}  conditionsNumber={this.state.conditionsNumber} trueFields={this.state[IDENTIFIERS.KEY+"Fields_true"]} falseFields={this.state[IDENTIFIERS.KEY+"Fields_false"]}
                            trueCondition={trueConditionKey} falseCondition={falseConditionKey} unmount={this.unmount} dataColumns={this.props.dataColumns} fieldsConcat={this.state[IDENTIFIERS.KEY+"FieldsConcat"]} trueFieldsConcat={this.state[IDENTIFIERS.KEY+"TrueFieldsConcat"]}
                            falseFieldsConcat={this.state[IDENTIFIERS.KEY+"FalseFieldsConcat"]} updateFields={this.updateFields}
                        />
                        <VectorIdentifier key="VectorIdentifier_2" ref={el => this.numberRef = el} identifierName={IDENTIFIERS.DISPLAY_NAME.NUMBER} isKey={false} isNumber={true} isName={false}  identifier={IDENTIFIERS.NUMBER} fieldQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_NUMBER_FIELD}
                            fileQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_NUMBER_FILE} fileOptions={this.state.masterAndTDFiles}  status={this.props.newRow}
                            fieldsValue={numberFieldsValue} updateVectorAttribute={this.updateVectorAttribute} getAllFileTypeColumns={this.getAllFileTypeColumns}
                            fields={this.state[IDENTIFIERS.NUMBER+"Fields"]} connectors={this.props.connectors} nameRef={this.nameRef} keyRef={this.keyRef}  type={"Number"} updateFields={this.updateFields}
                            is_default={this.vectorMappingObj[VECTOR_MAPPING.ATTR.IS_DEFAULT]} getObjOfSubtypeAttr={this.getObjOfSubtypeAttr} oldFields = {copyObjectValues(this.state[IDENTIFIERS.KEY+"Fields"])}
                            isAdvanced={this.state.isAdvanced} conditionsNumber={this.state.conditionsNumber}  trueFields={this.state[IDENTIFIERS.NUMBER+"Fields_true"]} falseFields={this.state[IDENTIFIERS.NUMBER+"Fields_false"]}
                            // trueCondition={this.state.isAdvanced &&  this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE].result  ?  (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).conditions[0].result : this.state["trueCondition_"+IDENTIFIERS.NUMBER]  }
                            // falseCondition={this.state.isAdvanced &&  this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE] && this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE].result ? (this.vectorMappingObj[VECTOR_MAPPING.ATTR.NUMBER_VALUE]).result : this.state["falseCondition_"+IDENTIFIERS.NUMBER] }
                             unmount={this.unmount} trueCondition={trueConditionNumber} falseCondition={falseConditionNumber} dataColumns={this.props.dataColumns} fieldsConcat={this.state[IDENTIFIERS.NUMBER+"FieldsConcat"]} trueFieldsConcat={this.state[IDENTIFIERS.NUMBER+"TrueFieldsConcat"]} falseFieldsConcat={this.state[IDENTIFIERS.NUMBER+"FalseFieldsConcat"]}
                        />
                        <VectorIdentifier key="VectorIdentifier_3" ref={el=>this.nameRef = el} identifierName={IDENTIFIERS.DISPLAY_NAME.NAME} isKey={false} isNumber={false} isName={true} identifier={IDENTIFIERS.NAME} fieldQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_NAME_FIELD} 
                            fileQuestion={MESSAGES.VECTOR_MAPPING.CHOOSE_NAME_FILE} fileOptions={this.state.masterAndTDFiles}  status={this.props.newRow}
                            fieldsValue={nameFieldsValue} updateVectorAttribute={this.updateVectorAttribute} getAllFileTypeColumns={this.getAllFileTypeColumns} unmount={this.unmount}
                            fields={this.state[IDENTIFIERS.NAME+"Fields"]} connectors={this.props.connectors} keyRef={this.keyRef} numberRef = {this.numberRef}type={"Name"} updateFields={this.updateFields}
                            is_default={this.vectorMappingObj[VECTOR_MAPPING.ATTR.IS_DEFAULT]} getObjOfSubtypeAttr={this.getObjOfSubtypeAttr} oldFields = {copyObjectValues(this.state[IDENTIFIERS.KEY+"Fields"])}
                            isAdvanced={this.state.isAdvanced} conditionsNumber={this.state.conditionsNumber} trueFields={this.state[IDENTIFIERS.NAME+"Fields_true"]} falseFields={this.state[IDENTIFIERS.NAME+"Fields_false"]}
                            trueCondition={trueConditionName} falseCondition={falseConditionName} dataColumns={this.props.dataColumns} fieldsConcat={this.state[IDENTIFIERS.NAME+"FieldsConcat"]} trueFieldsConcat={this.state[IDENTIFIERS.NAME+"TrueFieldsConcat"]}
                            falseFieldsConcat={this.state[IDENTIFIERS.NAME+"FalseFieldsConcat"]}
                   />
                    </div>
                    
                    <div id="submit_GL" className="uk-display-flex justify-content-end uk-margin-small-right uk-margin-small-bottom">
                        <Button 
                            label={"Submit"}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}  
                            onBtnClick={this.setVectorMapping}
                        />
                        <Button 
                            label={lang.modal.buttons.cancel}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-margin-default-left"
                            data-dismiss="modal"
                            onBtnClick={this.hideGLAccount}
                        />
                    </div>
                    </div>
                </div>
             </div>
        )
    }

    //#region mapping a calculated ps line
    calculateAmountOfCalculatedLine(formula) {
        let _this = this;
        let tempFormula = "";
        let finalAmount = 0;
        let amountsMap = {};
        if(this.formulaDRef.validateFormulaResult(formula)) {
            formula.map(item=>{
                if([FORMULA_ELEMENT_TYPE.OPERATOR, FORMULA_ELEMENT_TYPE.CONTROL].includes(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE])) {
                    tempFormula += item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE];
                } else {
                    //fetch the amount from the data in the table on the left (might be updated)
                    let ck = item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE];
                    if(!amountsMap[ck]) {   //in case amount of this costkey was already fetched, use from map instead of relooping
                        let line = getEmbeddedChild(_this.props.pssFields, _children, _costKey, ck);
                        let amount = 0;
                        if(line && line[_costType] === costtype.calculated) {
                            amount = this.calculateAmountOfCalculatedLine(line[_mapFormula]);   //if using a calculated inside a calculated, translate both formulas
                        } else {
                            amount = this.props.getParentAmount(line, ck);
                        }
                        
                        amountsMap[ck] = amount;
                    }
                    tempFormula += "("+ amountsMap[ck] +")";
                }
            });

            try {
                finalAmount = eval(tempFormula);
            } catch(e) {
                finalAmount = 0;
            }
        }

        return is_aN(finalAmount) ? finalAmount : 0;
    }

    updateCalcPslAmount(formula) {
        if(!formula) {
            return;
        }
        this.setState({
            calculatedPslAmount: this.calculateAmountOfCalculatedLine(formula)
        })
    }

    appendToFormula($node, isNumBtn) {
        let value = isNumBtn ? $node.attr("node_value").trim() : $node.attr("node_"+_costKey);
        let type = isNumBtn ? $node.attr("node_type") : FORMULA_ELEMENT_TYPE.COLUMN;
        let _class = $node.attr("node_class");
        let result = copyObjectValues(this.calcPsl[_mapFormula]);

        result.push({
            [CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE]: type,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE]: value,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.CLASS]: _class,
        });

        this.calcPsl[_mapFormula] = result;
        this.updateCalcPslAmount(result);
    }

    updateFormula(formula) {
        this.calcPsl[_mapFormula] = formula;
        this.updateCalcPslAmount(formula);
    }

    renderMappingCalculated() {
        
        return (
            <div>
                {this.renderModal()}
                <div className="mapping-header profit-stack-mapping justify-content-between">
                    <span className="uk-text-nowrap uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">
                        {lang.ps_mapping.map_section_title.calculated + this.calcPsl[_name]}
                        <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={lang.ps_mapping.title_tooltip.calculated} />
                    </span>
                </div>
                <span className="fs-14">Description</span>
                <textarea id="metric_description" className="form-control uk-textarea input-default" placeholder={MESSAGES.calculated_mapping_description_placeholder} 
                   defaultValue={this.calcPsl[PS_MAPPING.FIELDS.DESCRIPTION]} onChange={(e)=>{this.onChangeMetricAttribute(METRICS_MAPPING.FIELDS.DESCRIPTION, e)}}>
                </textarea>
                <div className="uk-width-1-1">
                    <div className="uk-padding-xsmall-top uk-padding-small-bottom">
                        <ControlButtons appendToFormula={($node)=>this.appendToFormula($node, true)} numpadOnly={true} report={STAGING_SECTIONS.PROFIT_STACK_MAPPING}
                        />
                    </div>
                    <FormulaDrop ref={r => this.formulaDRef = r} appendToFormula={this.appendToFormula}
                        formula={this.calcPsl[_mapFormula]} isInnerComp={false} setFormulaIndex={this.setFormulaIndex}
                        deleteLastFormulaItem={this.deleteLastFormulaItem} resetFormulaChanges={this.resetFormulaChanges}
                        deleteFormula={this.deleteFormula} hasConditions={false} report={STAGING_SECTIONS.PROFIT_STACK_MAPPING}
                        updateFormulaInParent={this.updateFormula}
                    />
                    
                    <label className="uk-display-flex uk-flex-column width-200 uk-padding-large-top">
                        <span className="fs-14 uk-display-flex uk-flex-middle">{lang.pss_map_exception.titles.parsed_amount}<i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={"TBD"} /></span>
                        {this.props.isParsingAmount ?
                            <img className="width-15 uk-margin-default-top uk-margin-xsmall-left" src="/images/FhHRx.gif"/>
                            :
                            <Input className="form-control-height uk-background-default uk-margin-xsmall-top uk-border uk-border-rounded uk-padding-xsmall-left-right" value={formatValString(this.state.calculatedPslAmount, FormatTypes.AMOUNT)} disabled={true}/>
                        }
                    </label>
                </div>

                <div id="submit_GL" className="map-calculated-line uk-position-none gl-mapping-footer uk-display-flex justify-content-end uk-margin-remove">
                    <Button 
                        label={"Submit"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}  
                        onBtnClick={this.saveMappedCalculatedPSL}
                    />
                    <Button 
                        label={lang.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-margin-default-left"
                        data-dismiss="modal"
                        onBtnClick={this.hideGLAccount}
                    />
                </div>
            </div>
        )
    }
    //#endregion

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    renderModal = () => {
        return (
            <Modal
                id={"info-dialog"}
                openDialog={this.state.openInfoDialog}
                bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                dialogActions={this.openInfoDialogActions}
                closeClick={() => this.setInfoDialogOpen(false)}
                size={DIALOG_SIZE.MEDIUM}
            />
        )
    }

    render() {
        var obj = this;
        obj.setMappingReport(obj.props.stagingReport);
        if(obj.isPSCalculated){
            obj.calcPsl = copyObjectValues(obj.props.pslLine);
        }
        if(this.isVectorMapping) {
            return (
                this.renderVectorMapping()
            );
        } else if(this.isCalculatedCols) {
            return (
                <ColumnFormula calculatedColsFileOptions={this.state.engineFiles}
                    operatedColumn={this.props.operatedColumn} rowPosition={this.props.rowPosition}
                    setRowFormula={this.props.setRowFormula} hideFormula={this.props.hideFormula}
                    scenarioId={this.props.scenarioId} getColumnValues={this.getColumnValues} calcColsData={this.props.calcColsData}
                    getAllFileTypeColumns={this.getAllFileTypeColumns} scenarioStatus={this.props.scenarioStatus}/>
            )
        } else if(this.isPSCalculated) {
            return (
                this.renderMappingCalculated()
            );
        }
        var buttonTitle = this.props.isManageExclusions ? "Apply" :"Submit"
        var obj = this;
        let allTypeColumns = this.props.allTypeColumns;
        var stringifiedFilter = this.state.filter !== "" && this.state.filter !== undefined ? this.state.filter : "";
        // var stringifiedAncillaryFilter = this.state.ancillaryFilter !== "" && this.state.ancillaryFilter !== undefined ? this.state.ancillaryFilter : "";
        var engineFilter = stringifiedFilter && stringifiedFilter.length  ? stringifiedFilter : this.isExclusions ? this.state.jsonExclusionFilter : "";
        // var ancillaryFilter = stringifiedAncillaryFilter && stringifiedAncillaryFilter.length  ? stringifiedAncillaryFilter :  JSON.stringify({filter:[]});
        var filter = "";
        let tempFilter = [];
        var filters=[];
        if(stringifiedFilter.length > 0) {
            // let parsedFilter = JSON.parse(stringifiedFilter.replaceAll("'","\"")).filter;
            let parsedFilter = JSON.parse(stringifiedFilter).filter ? JSON.parse(stringifiedFilter).filter : [];
            for(var e in parsedFilter) {
                var values = [];
                //the value might be "" when operator is empty or not empty
                if(![MESSAGES.ui_filter.dropdowns.functions.greater.value, MESSAGES.ui_filter.dropdowns.functions.less.value].includes(parsedFilter[e].operator) && parsedFilter[e].value !== "") {
                    parsedFilter[e].value.forEach(function(val, key){
                        values.push(val.value.toLowerCase());    //setting everything to lower case so that the filter becomes case insensitive
                    });
                }
                if (parsedFilter[e].operator.indexOf(MESSAGES.ui_filter.dropdowns.functions.empty.value) > -1) {
                    tempFilter.push(parsedFilter[e].field + " " + getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, parsedFilter[e].operator, "value")[0].label);   
                } else {
                    tempFilter.push(parsedFilter[e].field + " " + parsedFilter[e].operator + " " + ( values.length > 0 ? values.join(",") : parsedFilter[e].value));
                }
            }
            filter = tempFilter.join(", ");
        }

        var filterFields = this.state.ancillaryFileColumns.length ? this.state.ancillaryFileColumns : this.state.fields;
        var status = this.returnDisabledStatus();
        //setting the title of the component
        var compTitle = "";
        if(this.isProfitStackMapping && this.isExclusions) {
            compTitle = "Manage Exclusions";
        } else {
            compTitle = "Map - ";
            if(this.isProfitStackMapping) {
                compTitle += this.props.profitStackLine;
            } else if(this.isMetricMapping) {
                compTitle += this.state.mappedMetric[METRICS_MAPPING.FIELDS.METRIC];
            }
        }
        
        var totalAccountTitle = this.isProfitStackMapping ? 
            this.state.chosenAccountType.label === ACCOUNT_AMOUNT_TITLES.UNASSIGNED_COMBINATION ? "Combinations to be excluded" : this.state.chosenAccountType.label : "Total Entities";
        var totalAmountTitle = this.isProfitStackMapping ? 
            this.state.chosenAccountType.label === ACCOUNT_AMOUNT_TITLES.UNASSIGNED_COMBINATION ? "Amount to be excluded" : this.state.chosenAccountType.label.replace("Combinations", " Amount") : "Total Amount";
        
        var tooltipMessage = this.isProfitStackMapping && this.isExclusions ? MESSAGES.profit_stack_manage_exclusions_title : 
            this.isProfitStackMapping && !this.isExclusions  ? MESSAGES.profit_stack_map_title : this.isMetricMapping ? MESSAGES.metrics_map_title : "";
        var periodMessage = MESSAGES.profit_stack_map_selectd_period.replace('{x}', this.state.periodName);

        var isCCToggleDisabled = false;
        var ccToggleTooltip = undefined;

        if(this.isMetricMapping) {
            if(this.state.mappedMetric && this.state.mappedMetric.vector && this.state.mappedMetric.vector.toUpperCase() === this.props.costCenter.toUpperCase()) {
                isCCToggleDisabled = true;
                ccToggleTooltip = MESSAGES.toggle_off_branch.replace(new RegExp('\\[Branch]', 'g'), this.props.costCenter);
            } else if(this.state.fileHasCC === false) {
                isCCToggleDisabled = true;
                ccToggleTooltip = MESSAGES.toggle_off_tooltip.replace(new RegExp('\\[Branch]', 'g'), this.props.costCenter);
            } else if(this.state.mappedMetric && this.state.mappedMetric.rule && this.state.mappedMetric.rule !== ''){
                isCCToggleDisabled = true;
                ccToggleTooltip = MESSAGES.toggle_rule_tooltip.replace(new RegExp('\\[Branch]', 'g'), this.props.costCenter);
            }
        }
        return(
            <div style={{height:"100%"}}>
             {this.renderModal()}
            <div className="mapping-header profit-stack-mapping justify-content-between">
                    <span className="uk-text-nowrap uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">
                        {compTitle}<i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={tooltipMessage} />
                    </span>
                    {
                        this.isProfitStackMapping ?
                            <span className="text-grey fs-14 uk-margin-medium-right-left uk-display-flex">
                                {periodMessage}
                            </span>
                        : ""
                    }
                    {this.isExclusions ?
                        <DropDown
                            id="select-excluded-accounts"
                            className="uk-display-inline-block width-200 uk-margin-medium-right input__dropdown"
                            name="excluded-accounts" 
                            placeholder="Choose an excluded account"
                            value={findOptionByKey(this.state.accountTypes || [], this.state.chosenAccountType.value || this.state.accountTypes[0].value)}
                            options={this.state.accountTypes || []}
                            onChange={this.onChangeExcludedAccount}
                            type={DROPDOWN_TYPE.INPUT}
                        /> 
                        : ""
                        
                    }
                </div>

                {/** Metric name input field */}
                <div className={"metrics_mapping_edit_container"} style={{height:"96%", overflow:"scroll"}}>
                {this.isMetricMapping ?
                    <div className="uk-display-inline-flex uk-width-1-1">
                        <div>
                            <div className="uk-display-flex uk-flex-middle uk-margin-xsmall-bottom">
                                <span className="fs-14">{METRICS_MAPPING.TITLES.METRIC}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={"TBD"} />
                            </div>
                            <Input id="mapped-metric-name" className="form-control width-250" name="mapped-metric-name" placeholder="Name your metric" readOnly={false} value={this.state.mappedMetric[METRICS_MAPPING.FIELDS.METRIC]}  onChange={(e)=>this.onChangeMetricAttribute(METRICS_MAPPING.FIELDS.METRIC, e)}/>
                            {this.isNameValid !== "" ?
                                <p id="calc-col-warn" className="red italic uk-margin-top uk-margin-large-right">{this.isNameValid}</p>
                                : ""}
                            {this.isDuplicateName ?
                                <p id="calc-col-warn" className="red italic uk-margin-top uk-margin-large-right">{MESSAGES.name_validation.already_used}</p>
                        : ""}
                        </div>   
                        {/** List of vectors */}
                        <div className="mrgl50">
                            <div className="uk-display-flex uk-flex-middle uk-margin-xsmall-bottom">
                                <span className="fs-14">{METRICS_MAPPING.TITLES.METRIC_VECTOR}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={"TBD"} />
                            </div>
                            <DropDown
                                id="select-vector"
                                className="uk-display-inline-block width-250 input__dropdown"
                                name="vector-list" 
                                placeholder="Choose a metric vector"
                                value={findOptionByKey(this.state.vectorList, this.state.mappedMetric ? this.state.mappedMetric[METRICS_MAPPING.FIELDS.VECTOR] : this.state.vectorList ? this.state.vectorList[0]: "")}
                                options={this.state.vectorList ? this.state.vectorList.filter(e=>e.label !== "TOTALCOMPANY") : this.state.vectorList}
                                onChange={(e)=>this.onChangeMetricAttribute(METRICS_MAPPING.FIELDS.VECTOR, e)}
                                type = {DROPDOWN_TYPE.INPUT}
                            />
                        </div>
                    </div>
                : ""}

                {/** Description */}
                <div>
                { this.isMetricMapping || (this.isProfitStackMapping && ! this.isExclusions)?
                        <div>
                            <span className="fs-14 uk-margin-xsmall-bottom">Description</span>
                            <textarea id="metric_description" className="form-control uk-textarea input-default" placeholder={this.isMetricMapping ? MESSAGES.metric_mapping_description_placeholder : MESSAGES.standard_mapping_description_placeholder} 
                               defaultValue ={this.state.mappedMetric ? this.state.mappedMetric[METRICS_MAPPING.FIELDS.DESCRIPTION]: ""}onChange={(e)=>{this.onChangeMetricAttribute(METRICS_MAPPING.FIELDS.DESCRIPTION, e)}}>
                            </textarea>
                        </div>
                        :""}
                        
                        {/** Cost Center for metrics - should be before the Filter when mapping a metric */}
                        {this.isMetricMapping ?
                        <div className="uk-margin-xmedium-top uk-width-1-1">
                            <div className="uk-display-flex uk-flex-middle uk-margin-xsmall-bottom">
                                <span className="fs-14">{"By "+ capitaliseFirstLetterAfterChar(this.props.costCenter)}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.metrics_map_cc} />
                            </div>
                            <div className="uk-display-inline-flex toggle-button-container" uk-tooltip={ccToggleTooltip}>
                                <p className="width-30 uk-text-xmedium" id="toggle_name">{parseBoolean(this.state.mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER]) ? CC_LABELS.YES: CC_LABELS.NO}</p>
                                    <SwitchToggle addedClassName={"uk-flex uk-flex-middle uk-background-default toggle-button"+ (isCCToggleDisabled ? " disabled" :"")} id={"toggle_button"} onChange={(e)=>{this.onChangeMetricAttribute(METRICS_MAPPING.FIELDS.COST_CENTER,e)}} value={parseBoolean(this.state.mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER]) ? CC_LABELS.ON : CC_LABELS.OFF} checked={parseBoolean(this.state.mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER])} />
                            </div>
                        </div>                    
                :"" }
                </div>
        

                {/** Start of Filter */}
                { !this.props.isManageExclusions || this.state.chosenAccountType.value === ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION ?
                    <div className="uk-display-inline-flex uk-width-1-1 uk-flex-bottom">
                        {this.isProfitStackMapping && this.state.chosenAccountType.value !== ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION ?
                            <div>
                                <div className="fs-14 width-150 uk-display-inline-flex align-items-center uk-margin-xsmall-bottom">
                                    {MESSAGES.pss_map_exception.titles.plan_term}
                                    <i className="fs-12 fal fa-info-circle uk-margin-xsmall-right uk-margin-xsmall-left" uk-tooltip={this.isProfitStackMapping ? MESSAGES.profit_stack_plan_term_title : ""} />
                                </div>
                                <DropDown
                                    id="select-plan-term"
                                    className="width-150 input__dropdown"
                                    name="plan-term-list"
                                    placeholder="Choose a plan"
                                    value={findOptionByKeyValue(this.props.clientCostTerms, "cost_term_id", this.state.cost_term_id)}
                                    options={this.props.clientCostTerms}
                                    onChange={(option)=>this.onChangeCostTerm(option)}
                                    type={DROPDOWN_TYPE.INPUT}
                                />
                            </div>
                        : ""}
                        {this.isMetricMapping ?
                            <MetricConfiguration ref={el => this.metricConfRef = el} toggleDisabled={this.state.toggleDisabled} hasRowCC={this.state.hasRowCC} showLoader={this.state.showLoader} metricValidation={this.metricValidation} saveMetricMapping={this.props.saveMetricMapping} mappedMetric={this.state.mappedMetric}
                                showAdvancedFilter={this.showAdvancedFilter} allTypeColumns={this.props.allTypeColumns} updateFiles={this.updateFiles}
                                costCenter={this.props.costCenter} getConditionFormula={this.props.getConditionFormula} updateConditionFormula={this.props.updateConditionFormula} updateConfigFileCC={this.updateConfigFileCC}
                                calculatedCols={this.props.calculatedCols} checkForCostCenter={this.checkForCostCenter} appendToFormula={this.props.appendToFormula} getMetricDataValidation={this.getMetricDataValidation}/>
                            :
                            <React.Fragment>
                                <Button 
                                    id="submit-btn glFilter"
                                    label={"Filter"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-toggle='modal'
                                    className={"uk-margin-default-right-left"}
                                    leftIcon={<i className="far fa-filter uk-margin-small-right" aria-hidden="true"/>}
                                    onBtnClick={()=>{this.showPopup()}}
                                />
                                <Input className="form-control w100" id="filterFinalValue" type="text" readOnly />
                            </React.Fragment>
                        }
                    </div>
                    :""
                }
                </div>
                {/** End of Filter */}
                { !this.isMetricMapping ? 
                <div>
                    <div className="GL-accounts-table uk-border uk-background-default">
                        <div id={tableId} ref="mainTable"/>
                    </div>
                    <div className="uk-border uk-text-bold sum-div uk-background-primary uk-margin-xmedium-top">
                    <div>{totalAccountTitle}</div>
                    <div className="uk-margin-xmedium-right">{totalAmountTitle}</div>
                </div>
                <div className="uk-border sum-div uk-background-default mrgb50">
                    <div id="assignedAccount">{this.state.assignedAccount}</div>
                    <div className={(this.state.assignedAmount && this.state.assignedAmount < 0 ? "red " : "") + " "} id="assignedAmount">{formatValReact(this.state.assignedAmount, FormatTypes.AMOUNT)}</div>
                </div>  
                </div>
                    :""
                }
                {/** Map Exception for GL accounts **/}
                { this.isProfitStackMapping && !this.isExclusions?
                    <ExceptionDriver ref={r => this.exclDriverRef = r}
                        onChangeExceptionDriver={this.onChangeExceptionDriver}
                        onChangeCostTerm = {this.onChangeCostTerm}
                        onChangeAncillaryFile={this.setChosenAncillaryFile}
                        ancillaryFiles={this.state.ancillaryFiles}
                        ancillaryColumns={this.props.ancillaryColsAmounts}
                        pssSiblings={this.props.pssSiblings}
                        exceptionPSLOptions={this.props.exceptionPSLOptions}
                        leadingLine={this.props.pslLine}
                        mappedLine={this.props.mappedLine}
                        isVar={this.state.isVar}
                        // ancillaryFilter={ancillaryFilter}
                        scenario={this.props.scenarioId}
                        leadingPssId={this.props.leadingPssId}
                        leadingPssCostKey={this.props.leadingPssCostKey}
                        getAllFileTypeColumns={this.getAllFileTypeColumns}
                        getColumnValues={this.getColumnValues}
                        allAncillaryColumns={this.props.allAncillaryColumns.concat(this.props.exceptionMetrics)}
                        parseAncillaryColumnAmount={this.parseMapExceptionQuery}
                        parseMetricAmount={this.parseMetricAmount}
                        clientCostTerms={this.props.clientCostTerms}
                        costTerm={this.props.costTerm}
                        filter={this.state.filter}
                        costCenter = {this.props.costCenter}
                        calculatedCols={this.props.calculatedCols}
                        exceptionMetrics={this.props.exceptionMetrics}
                        timePeriod={this.state.periodName}
                        mappedLines={this.props.mappedLines}
                        />
                        
                :""}
                { !this.isMetricMapping ? 
                    <div id="submit_GL" className="map-calculated-line uk-position-none gl-mapping-footer uk-display-flex justify-content-end uk-margin-remove">
                        <Button 
                            label={buttonTitle}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}  
                            className={status}
                            onBtnClick={this.saveMapping}
                        />
                         <Button 
                            label={lang.modal.buttons.cancel}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-margin-default-left"
                            data-dismiss="modal"
                            onBtnClick={this.hideGLAccount}
                        />
                    </div>
                    : ""
                }
                

                <div id="filterPopUp" >
                    {this.state.showFilter ? this.isMetricMapping ?
                        <EngineFilterModal 
                            ref={el => this.filterModalRef = el}
                            scenario={this.props.scenario}
                            filter={this.state.advancedMetricFilter}
                            handleFilterRowChange={obj.handleFilterRowChange}
                            discardFilter={obj.discardFilter}
                            saveFilter={obj.saveFilter}
                            clearFilter={obj.clearFilter}
                            stagingReport={STAGING_SECTIONS.METRICS_MAPPING}
                            loadEntitiesFunc={obj.getColumnValues}
                            fileTypeOptions={obj.state.engineModalFiles}
                            vectorOptions={obj.props.vectorOptions}
                            hiddenVectors={obj.props.hiddenVectors}
                            periodName={obj.state.periodName}
                        />
                        :
                        <EngineFilter ref={el => this.engineFilterRef = el} fields={filterFields} setFilter={this.saveFilter} filter={engineFilter} onCancelFilter={()=>{}} isManageExclusions={this.props.isManageExclusions}
                            timePeriod={this.state.periodName} modalId={"filterEngineModal"} tableName={this.state.tableName} configure={false} ancillary={false} isProfitStackMapping={this.isProfitStackMapping} metric={this.state.mappedMetric[METRICS_MAPPING.FIELDS.RAW_FILE_DISPLAY_SUBTYPE]}/>
                        : "" }
                </div>
              
                <div id="confirmCombinationUncheckModal" className="modal fade uk-margin-xlarge-top" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content uk-padding-small">
                            <div className="uk-margin-bottom">
                            <Button 
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="close"
                                data-dismiss="modal"
                                leftIcon={<span aria-hidden="true">&times;</span>}
                            />
                            </div>
                            <div className="modal-body">
                                <h4 className="uk-margin uk-text-center message uk-width-1-2">{MESSAGES.combinations_unchecked}</h4>
                                <Button 
                                    label={"Confirm"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className={"uk-margin-top"}
                                    data-dismiss="modal"
                                    onBtnClick={()=>{this.showFilterModal()}}
                                />
                                <Button 
                                    label={lang.modal.buttons.cancel}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-align-right uk-margin-small-left uk-margin-top"
                                    data-dismiss="modal"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="mappingChanges" className="modal fade uk-margin-xlarge-top" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content uk-padding-small">
                            <div className="uk-margin-bottom">
                            <Button 
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="close"
                                data-dismiss="modal"
                                leftIcon={<span aria-hidden="true">&times;</span>}
                            />
                            </div>
                            <div className="modal-body">
                                <h4>Any GL Cost source change will affect below profit stack lines:</h4>
                                {this.state.profitStackLines ? 
                                this.state.profitStackLines.map(function(item){
                                    return <div key={item[_id]} className="uk-display-block uk-text-medium">{"- "+ item[PS_MAPPING.FIELDS.NAME]}</div>
                                })
                                : ""}
                                <div className="uk-flex uk-flex-right uk-margin-default-top">
                                <Button 
                                    label={"Done"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-margin-default-left"
                                    data-dismiss="modal"
                                    onBtnClick={()=>{this.callSaveMappedAncillary()}}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="combinationsChanges" className="modal fade uk-margin-xlarge-top" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content uk-padding-small">
                            <div className="uk-margin-bottom">
                            <Button 
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="close"
                                data-dismiss="modal"
                                leftIcon={<span aria-hidden="true">&times;</span>}
                                aria-label="Close"
                            />
                            </div>
                            <div className="modal-body">
                                <h4>This Change will remove combinations on below profit stack lines:</h4>
                                {this.props.pssSiblings ?
                                this.props.pssSiblings.map(function(item){
                                    if (item[PS_MAPPING.FIELDS.COST_KEY] !== obj.props.mappedLine[PS_MAPPING.FIELDS.COST_KEY]){
                                        return <div key={item[_id]} className="uk-display-block uk-text-medium">{"- "+ item[PS_MAPPING.FIELDS.NAME]}</div>
                                    }
                                })
                                :""}
                                 <Button 
                                    label={"Done"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-align-right uk-margin-small-left uk-margin-top"
                                    data-dismiss="modal"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
                
        );

    }

}

export default GLAccountList;